import 'react-app-polyfill/ie11'; // Polyfills for IE11 to deal with Array methods mostly
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'index.scss';
import CatalogHubApp from 'CatalogHubApp';
import * as serviceWorker from 'serviceWorker';
import WebFont from 'webfontloader';
import { IntlProvider } from 'react-intl';
//import { addLocaleData } from 'react-intl';
//import en from 'react-intl/locale-data/en';
//import es from 'react-intl/locale-data/es';
//import de from 'react-intl/locale-data/de';
import messages_en from 'translations/en.json';
import messages_es from 'translations/es.json';
import messages_de from 'translations/de.json';
import { fetchSessionState } from 'redux/hubStore';
import { isNullOrUndefined } from 'utils/object';
import { lowerKeyParams } from 'utils/object';
import 'utils/ie11polyfills';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

//addLocaleData([...en, ...es, ...de]);

const messageBundle = {
    en: messages_en,
    es: messages_es,
    de: messages_de
};

let defaults = {
    container: 'catalogRoot',
    appId: '59eff52adbfb47c9a24d16b46fe6d068',
    appAuthId: 'uZ4kAJ06RPxp4XOc',
    // Token for accessing ArcGIS Online/Portal
    token: '',
    tokenExpiry: -1,
    // Where is the ArcGIS Portal instance?
    portal: {
        url: 'https://www.arcgis.com/sharing/rest/',
        // And what is it - Online or (Portal or Enterprise)
        type: 'online',
        home: 'https://www.arcgis.com/'
    },
    // Other InstantAtlas ArcGIS apps...
    appLinks: {
        reportBuilder: {
            appId: 'a0905cde786f4b04a3220b44151845c5',
            url: 'https://www.reports.esriuk.com/'
        },
        dashboardBuilder: {
            url: 'https://dashboards.instantatlas.com/'
        },
        dataCatalogExplorer: {
            url: '/data-catalog-explorer/'
        }
    },
    fontFamily: 'Work Sans',
    fontSize: 16,
    // Called when the app is ready.
    onReady: () => {
        console.log('\n\nAPP READY');
    },
    // Called when an error occurs in the ArcGIS Online communication.
    onError: (err) => {
        console.log(err);
    },
    user: null,
    locale: 'en',
    messages: {},
    configPathPattern: '{appHost}/app-config-{domainKey}.json' // Overriding config based on domain - can be static file or micro-service URL with query
};

const launchAppWithSettings = (settings) => {
    ReactDOM.render(
        <IntlProvider locale={settings.locale} messages={settings.messages}>
            <CatalogHubApp
                onReady={settings.onReady}
                id={settings.appId}
                authId={settings.appAuthId}
                portalUrl={settings.portal.url}
                portalType={settings.portal.type}
                portalHome={settings.portal.home}
                token={settings.token}
                tokenExpiry={settings.tokenExpiry}
                user={settings.user}
                appLinks={settings.appLinks}
                onError={settings.onError}
                basename={settings.basename}
                inErrorState={settings.inErrorState}
                error={settings.error}
            />
        </IntlProvider>,
        document.querySelector('#' + settings.container)
    );
};

const isValidString = (str) => {
    return str !== undefined && str !== null && str !== '';
};

const launchWithConfig = async () => {
    // Standard ArcGIS naming...
    const host =
            typeof process !== 'undefined' &&
            typeof process.env !== 'undefined' &&
            !isNullOrUndefined(process) &&
            !isNullOrUndefined(process.env) &&
            !isNullOrUndefined(process.env.PUBLIC_URL)
                ? process.env.PUBLIC_URL
                : '/',
        configPath = host.replace(/\/$/, ''),
        activeAppPath = /^http|https:\/\//.test(configPath)
            ? configPath
            : `${window.location.protocol}//${window.location.hostname}${
                  window.location.port !== '' && window.location.port !== '80' && window.location.port !== '443'
                      ? `:${window.location.port}`
                      : ''
              }`;
    try {
        // Bypass licensing if we are running on "well known" URLs
        if (
            !/^https:\/\/[a-zA-Z0-9-.]+\.instantatlas\.com/.test(activeAppPath) &&
            !/^https:\/\/[a-zA-Z0-9-.]+\.esriuk\.com/.test(activeAppPath) &&
            activeAppPath !== 'https://ia-data-catalog-hub-test.azurewebsites.net' &&
            activeAppPath !== 'https://ia-apps-dev-builds.azurewebsites.net/data-catalog-hub' &&
            activeAppPath !== 'https://datacataloghub.localdev:3001' &&
            activeAppPath !== 'https://localhost:3001'
        ) {
            // TODO - migrate this to the Sweet-style licensing...
            const pathsList = await fetch(`${configPath}/app.licence`).then((lic) => {
                    return lic.text();
                }),
                paths = pathsList.split('$'),
                activePath = `${window.location.protocol}//${window.location.hostname}${
                    window.location.port !== '' ? `:${window.location.port}` : ''
                }${configPath}/`,
                activePathHash = Base64.stringify(sha256(activePath));
            if (paths.indexOf(activePathHash) < 0) {
                const msg = document.getElementById('appFailMessage'),
                    block = document.getElementById('appFailBlock');
                if (!isNullOrUndefined(msg) && !isNullOrUndefined(block)) {
                    msg.innerHTML = `InstantAtlas Data Catalog Hub is not licensed for use on site ${activePath}. Contact support@instantatlas.com`;
                    block.style.display = 'block';
                    return;
                } else throw new Error(`InstantAtlas Data Catalog Hub is not licensed for use on site ${activePath}`);
            }
        }
        let dynamicAppConfig = await fetch(`${configPath}/app-config.json`).then((res) => {
            return res.json();
        });
        // Special case - multi-site within a known domain
        const appDomainName = activeAppPath.match(
            /^https:\/\/hub-([a-zA-Z0-9-]+)\.instantatlas|esriuk\.com|https:\/\/(localhost|datacataloghub\.localdev):[0-9]{4}/
        );
        if (appDomainName !== null && Array.isArray(appDomainName) && appDomainName.length > 1) {
            try {
                const tempConfig = {
                        ...defaults,
                        ...dynamicAppConfig
                    },
                    subDomainName =
                        appDomainName[1] !== undefined
                            ? appDomainName[1]
                            : appDomainName[2] !== undefined
                            ? appDomainName[2]
                            : 'never-ever',
                    overridesPath = (tempConfig.configPathPattern || '{appHost}/app-config-{domainKey}.json')
                        .replace('{appHost}', configPath)
                        .replace('{subDomain}', subDomainName)
                        .replace('{domainKey}', subDomainName.replace(/[^0-9a-zA-Z]/g, '-')),
                    overridesAppConfig = await fetch(overridesPath).then((cfgRsp) => {
                        return cfgRsp.json();
                    });
                dynamicAppConfig = {
                    ...dynamicAppConfig,
                    ...overridesAppConfig
                };
            } catch (overrideEx) {
                console.log(
                    `Cannot override settings with customer-specific ones for key ${
                        appDomainName[1] || appDomainName[2]
                    }: ${overrideEx}`
                );
            }
        }
        // Override default settings with passed in options.
        let settings = Object.assign(defaults, dynamicAppConfig);
        // If running standalone, pull in the arguments from the address bar.
        const qs = lowerKeyParams(new URLSearchParams(window.location.search));
        const hashArgs = !isNullOrUndefined(window.location.hash)
            ? lowerKeyParams(new URLSearchParams(window.location.hash.substring(1)))
            : {};
        // Token?
        if (!isNullOrUndefined(qs.token)) settings.token = qs.token;
        if (!isNullOrUndefined(hashArgs.access_token)) {
            settings.token = hashArgs.access_token;
            settings.tokenExpiry = new Date().getTime() + 1000 * hashArgs.expires_in; // seconds to milliseconds
        }
        // Persisted token? May not have expired yet...
        const storedSettings = fetchSessionState('dataCatalogHubState');
        if (
            !isValidString(settings.token) &&
            !isNullOrUndefined(storedSettings) &&
            !isNullOrUndefined(storedSettings.hubAppSettings) &&
            isValidString(storedSettings.hubAppSettings.token)
        ) {
            settings.token = storedSettings.hubAppSettings.token;
            settings.tokenExpiry = storedSettings.hubAppSettings.tokenExpiry;
            if (settings.tokenExpiry < new Date().getTime()) {
                // Expired - clear it, force a re-auth
                settings.token = '';
                settings.tokenExpiry = -1;
            }
        }
        // Locale
        if (!isNullOrUndefined(qs.locale)) settings.locale = qs.locale;
        settings.messages = messageBundle[settings.locale]
            ? messageBundle[settings.locale]
            : settings.locale.split('-').length > 1 && messageBundle[settings.locale.split('-')[0]]
            ? messageBundle[settings.locale.split('-')[0]]
            : {};
        // Development overrides...
        if (
            typeof process !== 'undefined' &&
            typeof process.env !== 'undefined' &&
            process &&
            process.env &&
            process.env.NODE_ENV === 'development'
        ) {
            if (!isNullOrUndefined(qs.portalType)) settings.portal.type = qs.portalType;
        }
        WebFont.load({
            custom: {
                families: ['Avenir-light'],
                urls: [`${configPath}/static/css/esri-fonts-modern.css`]
            }
        });
        // Unregistered errors
        if (!isValidString(settings.appId) || settings.appId.substring(0, 1) === '<') {
            settings = Object.assign(defaults, {
                inErrorState: true,
                error: {
                    code: 503,
                    message:
                        'App "app-config.json" invalid - update required - see https://help.instantatlas.com/instantatlas-data-catalog/'
                }
            });
        }
        // Devious - sneak in basename here if it is not explicitly set...
        //if (isNullOrUndefined(settings.basename) && !isNullOrUndefined(window.location.pathname) && (window.location.pathname !== '/'))
        //{
        //    settings.basename = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/') + 1);
        //}
        // Auth or not auth? Launch...
        if (!isNullOrUndefined(settings.token) && settings.token.length > 10) {
            fetch(
                `${settings.portal.url.replace(/^\/(.*)\/$/, '$1')}/portals/self?f=json&token=${settings.token}`
            ).then((portalRsp) => {
                portalRsp.json().then((portalSelf) => {
                    if (!portalSelf.error) {
                        settings.user = portalSelf.user;
                        //if (!isNullOrUndefined(portalSelf.urlKey) && !isNullOrUndefined(portalSelf.customBaseUrl))
                        //    settings.portal.url = `https://${portalSelf.urlKey}.${portalSelf.customBaseUrl}/sharing/rest/`;
                    } else {
                        settings.token = null;
                        settings.user = null;
                    }
                    launchAppWithSettings(settings);
                });
            });
        } else launchAppWithSettings(settings);
    } catch (err) {
        // Override default settings with passed in options.
        let settings = Object.assign(defaults, {
            inErrorState: true,
            error: {
                code: 500,
                message: err
            }
        });
        launchAppWithSettings(settings);
    }
};

launchWithConfig();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
