import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { setTreeState } from 'redux/hubStore';
import { ThemeTreePanel } from 'data-catalog-js-api';

// Enable access to redux store.
const mapStateToProps = state =>
{
    return {
        treeState: state.treeState
    };
};

const actionCreators = {
    setTreeState
};

// Re-export the ThemeTreePanel, but now bonded to redux store...
export default connect(mapStateToProps, actionCreators)(ThemeTreePanel);