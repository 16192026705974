import React, { PureComponent } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'pages/Manager.scss';
import { getInfo } from 'utils/auth';
import ModalDialog from 'components/ModalDialog';
import ProgressMessage from 'components/ProgressMessage';
import DraggableRow from 'components/manager/DraggableRow';

class DatesDialog extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            instances: [],
            indicator: {
                id: null,
                name: null
            },
            geo: {
                id: null,
                name: null,
                url: null
            },
            active: {},
            changes: [],
            loading: true,
            catalog: null
        };
        this.onRefresh = this.onRefresh.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.handleNewConnectionClick = this.handleNewConnectionClick.bind(this);
    }

    onActiveChange(evt, rowId) {
        this.setState({
            active: {
                id: rowId,
                editing: false
            }
        });
    }

    onEditChange(evt, rowId) {
        evt.preventDefault();
        evt.stopPropagation();
        this.setState({
            active: {
                id: rowId,
                editing: true
            }
        });
    }

    onRename(evt, rowId) {
        //evt.preventDefault();
        evt.stopPropagation();
        this.setState({
            active: {
                id: rowId,
                editing: true,
                value: evt.currentTarget.value
            }
        });
    }

    onRenameCommit(evt, rowId) {
        evt.preventDefault();
        evt.stopPropagation();
        const { changes, instances, active } = this.state;
        if (active.value !== undefined && active.value !== null) {
            let i = -1;
            if ((i = changes.findIndex((c) => c.id === rowId && c.action === 'rename')) < 0) {
                changes.push({
                    action: 'rename',
                    id: rowId,
                    value: active.value,
                    itemType: 'Instance'
                });
            } else if (i >= 0) changes[i].value = active.value;
            i = instances.findIndex((ii) => ii['ID'] === active.id);
            if (i >= 0) instances[i]['Name'] = active.value;
        }
        this.setState({
            active: {
                id: rowId,
                editing: false
            },
            instances: instances,
            changes: changes
        });
    }

    onRenameCancel(evt, rowId) {
        evt.preventDefault();
        evt.stopPropagation();
        this.setState({
            active: {
                id: rowId,
                editing: false
            }
        });
    }

    onDelete(evt, rowId) {
        evt.preventDefault();
        evt.stopPropagation();
        const { changes, instances } = this.state;
        let i = -1;
        if ((i = changes.findIndex((c) => c.id === rowId)) < 0) {
            changes.push({
                action: 'delete',
                id: rowId,
                itemType: 'Instance'
            });
        }
        if (i >= 0) {
            const c = changes.splice(i, 1)[0];
            if (c.action === 'add') {
                i = instances.findIndex((ii) => ii['ID'] === c.id);
                if (i >= 0) instances.splice(i, 1);
            }
        }
        this.setState({
            changes: changes,
            instances: instances,
            active: {}
        });
    }

    onMetadata(evt, rowId) {
        if (this.props.onMetadataClick !== undefined) {
            const { item } = this.props,
                { instances } = this.state,
                i = instances.find((ii) => ii['ID'] === rowId);
            if (i !== undefined) {
                // Pass everything back to a HOC/parent handler...
                this.props.onMetadataClick({
                    instance: {
                        id: i['ID'],
                        name: i['Name']
                    },
                    indicator: item,
                    geo: item.geo
                });
            }
        }
    }

    onRefresh(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        const { catalog, item } = this.props,
            { instances, changes, geo } = this.state;
        if (
            item !== undefined &&
            instances !== undefined &&
            instances !== null &&
            instances.length > 0 &&
            catalog !== undefined
        ) {
            // Self refresh...
            this.setState({
                loading: true
            });
            const url = instances[0]['Service_Url'],
                existing = [];
            let maxOrder = 0;
            for (let i of instances) {
                existing.push({
                    id: i['ID'],
                    field: i['Field_ID'],
                    name: i['Name']
                });
                maxOrder = Math.max(i['Item_Order'], maxOrder);
            }
            catalog.getAvailableDates(url, item.id, item.name, item.shortName, existing).then((extraDatesFirstPass) => {
                const secondPassPromise =
                    extraDatesFirstPass.length < 1
                        ? catalog.getAvailableDates(
                              url,
                              item.id.replace(/[^0-9a-zA-Z]/g, ''),
                              item.name,
                              item.shortName,
                              existing
                          )
                        : Promise.resolve(extraDatesFirstPass);
                secondPassPromise.then((extraDates) => {
                    if (extraDates.length > 0) {
                        for (var x of extraDates) {
                            maxOrder++;
                            const managedField = x.field.match(/^ID([0-9a-z_]{1,12})D([0-9]{8,16}|LM[0-9]{3,6})$/m),
                                nii = {
                                    ID: `${geo.id}${item.id}D${
                                        managedField !== null ? managedField[2] : x.name.replace(/[^a-zA-Z0-9]/g, '')
                                    }`,
                                    Field_ID: x.field,
                                    Service_Url: url,
                                    Name: x.name,
                                    Item_Order: maxOrder,
                                    Indicator_ID: item.id,
                                    Geo_ID: geo.id,
                                    Item_Type: 'Instance'
                                };
                            instances.push(nii);
                            changes.push({
                                action: 'add',
                                id: nii['ID'],
                                value: nii,
                                itemType: 'Instance'
                            });
                        }
                    }
                    this.setState({
                        loading: false,
                        instances: instances,
                        changes: changes
                    });
                });
            });
        }
    }

    onDrop(dragIndex, hoverIndex) {
        const { instances, changes } = this.state,
            ii = instances.splice(dragIndex, 1)[0];
        instances.splice(hoverIndex, 0, ii);
        this.setState(
            {
                instances: instances,
                active: {
                    id: ii['ID'],
                    editing: false
                }
            },
            () => {
                let min = Number.MAX_SAFE_INTEGER;
                for (let i of instances) min = Math.min(min, i['Item_Order']);
                let fchanges = changes.filter((c, index, arr) => {
                    return c.action !== 'reorder';
                });
                for (let i of instances) {
                    i['Item_Order'] = min;
                    fchanges.push({
                        action: 'reorder',
                        id: i['ID'],
                        value: min,
                        itemType: 'Instance'
                    });
                    min++;
                }
                this.setState({
                    instances: instances,
                    changes: fchanges,
                    active: {
                        id: ii['ID'],
                        editing: false
                    }
                });
            }
        );
    }

    handleNewConnectionClick() {
        const { onNewConnectionClick, onClose, item } = this.props;
        if (onNewConnectionClick !== undefined)
            onNewConnectionClick(
                item.id,
                item.geo !== undefined ? item.geo.id : null,
                item.name,
                item.geo !== undefined ? item.geo.name : null
            );
        else if (onClose !== undefined) onClose(this); // leave the close to the parent?
    }

    onSaveClick() {
        if (this.props.onSave) this.props.onSave(this.state.changes);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.show) {
            this.setState(
                {
                    loading: true
                },
                () => {
                    this.onOpen();
                }
            );
        }
    }

    componentDidMount() {
        this.setState(
            {
                loading: true
            },
            () => {
                this.onOpen();
            }
        );
    }

    onOpen() {
        const { catalog, item, tokenManager } = this.props;
        catalog.getDates(item.id, item.geo.id).then((instanceSet) => {
            const dataUrl = instanceSet.length > 0 ? instanceSet[0]['Service_Url'] : null,
                prom = dataUrl != null ? getInfo(dataUrl, catalog.token, tokenManager) : Promise.resolve(null);
            prom.then((lyrInfo) => {
                this.setState({
                    loading: false,
                    indicator: {
                        id: item.id,
                        name: item.name
                    },
                    geo: item.geo,
                    instances: instanceSet,
                    layer: {
                        url: dataUrl,
                        name: lyrInfo != null ? lyrInfo.name : null
                    },
                    changes: []
                });
            });
        });
    }

    render() {
        const { onMetadataClick, show, onClose } = this.props,
            { instances, active, changes, loading, layer } = this.state,
            rows = instances.map((i, index) => {
                const ac = i['ID'] === active.id ? 'active' : '',
                    nw = i['OBJECTID'] === undefined || i['OBJECTID'] === -1 ? 'addition' : '',
                    mc =
                        changes.find((c) => c.id === i['ID'] && c.action === 'delete') !== undefined
                            ? 'marked-for-delete'
                            : '',
                    isEditable = i['ID'] === active.id && active.editing;
                return (
                    <DraggableRow
                        key={index}
                        index={index}
                        id={'row' + i['ID']}
                        className={`row draggable-row ${ac} ${mc} ${nw}`.trim()}
                        data-instance-id={i['ID']}
                        moveRow={this.onDrop}
                    >
                        {isEditable ? (
                            <div className="col-md-4 date-name">
                                <span
                                    className="form-inline"
                                    style={{ margin: '-4px 0 -4px -4px', display: 'inline-block' }}
                                >
                                    <input
                                        className="form-control"
                                        onKeyUp={(e) => this.onRename(e, i['ID'])}
                                        defaultValue={i['Name']}
                                    />
                                    <span className="btn-group btn-group-sm" style={{ marginLeft: '10px' }}>
                                        <button
                                            className="btn btn-default"
                                            onClick={(e) => this.onRenameCommit(e, i['ID'])}
                                        >
                                            <i className="fas fa-lg fa-save"></i>
                                        </button>
                                        <button
                                            className="btn btn-default"
                                            onClick={(e) => this.onRenameCancel(e, i['ID'])}
                                        >
                                            <i className="fas fa-lg fa-times"></i>
                                        </button>
                                    </span>
                                </span>
                            </div>
                        ) : (
                            <div className="col-md-4 date-name" onClick={(e) => this.onActiveChange(e, i['ID'])}>
                                <span className="i-name">{i['Name']}</span>
                            </div>
                        )}
                        <div className="col-md-2 date-order" onClick={(e) => this.onActiveChange(e, i['ID'])}>
                            {i['Item_Order']}
                        </div>
                        <div
                            className="col-md-3 date-field"
                            data-field-name={i['Field_ID']}
                            onClick={(e) => this.onActiveChange(e, i['ID'])}
                        >
                            {i['Field_ID']}
                        </div>
                        <div className="col-md-3" onClick={(e) => this.onActiveChange(e, i['ID'])}>
                            {mc !== '' ? (
                                <div className="iao-controls btn-group btn-group-sm">
                                    <button
                                        type="button"
                                        className="btn btn-default"
                                        onClick={(e) => this.onDelete(e, i['ID'])}
                                        title="Undo"
                                    >
                                        <i className="fas fa-undo fa-lg"></i>
                                        <span className="sr-only">Undo Delete</span>
                                    </button>
                                </div>
                            ) : (
                                <div
                                    className="iao-controls btn-group btn-group-sm"
                                    style={{ display: isEditable ? 'none' : 'inherit' }}
                                >
                                    <button
                                        type="button"
                                        className="btn btn-default"
                                        onClick={(e) => this.onEditChange(e, i['ID'])}
                                        disabled={isEditable}
                                        title="Rename"
                                    >
                                        <i className="fas fa-pencil-alt fa-lg"></i>
                                        <span className="sr-only">Rename</span>
                                    </button>
                                    {onMetadataClick !== undefined ? (
                                        <button
                                            type="button"
                                            className="btn btn-default"
                                            onClick={(e) => this.onMetadata(e, i['ID'])}
                                            data-action="metadata"
                                            title="Metadata"
                                            disabled={isEditable || nw !== ''}
                                        >
                                            <i className="fas fa-info-circle fa-lg"></i>
                                            <span className="sr-only">Metadata</span>
                                        </button>
                                    ) : null}
                                    <button
                                        type="button"
                                        className="btn btn-default"
                                        onClick={(e) => this.onDelete(e, i['ID'])}
                                        disabled={isEditable}
                                        title="Delete"
                                    >
                                        <i className="fas fa-calendar-times fa-lg"></i>
                                        <span className="sr-only">Delete</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    </DraggableRow>
                );
            });
        return !show ? null : (
            <ModalDialog
                title={
                    <FormattedMessage
                        id="manager.datesDialog.title"
                        defaultMessage="{indicator} | Dates | {geo}"
                        values={{
                            indicator: this.state.indicator.name,
                            geo: this.state.geo.name
                        }}
                    />
                }
                buttons={[
                    instances !== undefined && instances.length > 0 ? (
                        <div className="pull-left">
                            <button type="button" className="btn btn-secondary" onClick={this.onRefresh}>
                                <FormattedMessage
                                    id="manager.datesDialog.button.check"
                                    defaultMessage="{icon} Check Fields"
                                    values={{
                                        icon: <i className="fas fa-redo"></i>
                                    }}
                                />
                            </button>
                        </div>
                    ) : (
                        <div className="pull-left">
                            <button type="button" className="btn btn-secondary" onClick={this.handleNewConnectionClick}>
                                <FormattedMessage
                                    id="manager.datesDialog.button.addConnection"
                                    defaultMessage="{icon} Add Indicator Connection"
                                    values={{
                                        icon: <i className="fas fa-plug fa-rotate-90"></i>
                                    }}
                                />
                            </button>
                        </div>
                    ),
                    <button
                        type="button"
                        className="btn btn-default btn-primary"
                        onClick={(e) => this.onSaveClick(e)}
                        disabled={changes.length < 1}
                    >
                        <FormattedMessage
                            id="manager.datesDialog.button.save"
                            defaultMessage="{icon} Save Changes"
                            values={{
                                icon: <i className="fas fa-save"></i>
                            }}
                        />
                    </button>,
                    <button type="button" className="btn btn-default btn-secondary" data-dismiss="modal">
                        <FormattedMessage
                            id="manager.datesDialog.button.cancel"
                            defaultMessage="{icon} Cancel"
                            values={{
                                icon: <i className="fas fa-times"></i>
                            }}
                        />
                    </button>
                ]}
                show={show}
                large={true}
                scroll={true}
                onClose={onClose}
            >
                <div>
                    <div style={{ float: 'left', marginRight: 10 + 'px', marginBottom: 10 + 'px' }}>
                        <i className="far fa-calendar-alt" style={{ fontSize: '48px' }}></i>
                    </div>
                    {instances !== undefined && instances.length > 0 ? (
                        <div style={{ marginLeft: 68 + 'px' }}>
                            <div className="i-after">
                                <p>
                                    <FormattedMessage
                                        id="manager.datesDialog.label"
                                        defaultMessage="The following dates are available for {indicator} for layer {geo} (in feature service {url})."
                                        values={{
                                            indicator: <strong className="i-name">{this.state.indicator.name}</strong>,
                                            geo: <strong className="g-name">{this.state.geo.name}</strong>,
                                            url: (
                                                <a href={`${layer.url}`} target="iaoArcWindow" className="svc-url">
                                                    <span className="svc-name">{layer.name}</span>{' '}
                                                    <i className="fas fa-external-link-alt small"></i>
                                                </a>
                                            )
                                        }}
                                    />
                                </p>
                                <p>
                                    <FormattedMessage
                                        id="manager.datesDialog.instructions"
                                        defaultMessage="Click on a row to activate it. You can then drag it to a new position, rename that date, add metadata or delete it entirely."
                                    />
                                </p>
                            </div>
                            <div>
                                {loading ? (
                                    <div className="text-center" style={{ fontSize: '64px' }}>
                                        <ProgressMessage message={''} />
                                    </div>
                                ) : (
                                    <div className="dates-table container container-fluid table table-striped">
                                        <div className="row">
                                            <div className="col-md-4 date-name">
                                                <FormattedMessage
                                                    id="manager.datesDialog.dateColumn.header"
                                                    defaultMessage="Date"
                                                />
                                            </div>
                                            <div className="col-md-2 date-order">
                                                <FormattedMessage
                                                    id="manager.datesDialog.orderColumn.header"
                                                    defaultMessage="Order"
                                                />
                                            </div>
                                            <div className="col-md-3 date-field">
                                                <FormattedMessage
                                                    id="manager.datesDialog.fieldNameColumn.header"
                                                    defaultMessage="Field"
                                                />
                                            </div>
                                            <div className="col-md-3">&nbsp;</div>
                                        </div>
                                        {rows}
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div style={{ marginLeft: 68 + 'px' }}>
                            <div className="i-after">
                                {loading ? (
                                    <div className="text-center" style={{ fontSize: '64px' }}>
                                        <ProgressMessage message={''} />
                                    </div>
                                ) : (
                                    <p>
                                        <FormattedMessage
                                            id="manager.datesDialog.label.empty"
                                            defaultMessage="No dates are available for {indicator} for layer {geo}. You need to add some via the {button} button."
                                            values={{
                                                indicator: (
                                                    <strong className="i-name">{this.state.indicator.name}</strong>
                                                ),
                                                geo: <strong className="g-name">{this.state.geo.name}</strong>,
                                                button: (
                                                    <strong>
                                                        Add Indicator Connection{' '}
                                                        <i className="fas fa-plug fa-rotate-90"></i>
                                                    </strong>
                                                )
                                            }}
                                        />
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </ModalDialog>
        );
    }
}

export default injectIntl(DatesDialog, { withRef: true });
