import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Link } from 'react-router-dom';
import hubStore from 'redux/hubStore';
//import Router from 'router/Router.js';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import HomePage from 'pages/HomePage';
import AppCannotStartPage from 'pages/AppCannotStartPage';
import ManagerPage from 'pages/ManagerPage';
import InspectorPage from 'pages/InspectorPage';
import WebMapBuilderPage from 'pages/WebMapBuilderPage';
import DataExplorerPage from 'pages/DataExplorerPage';
import MetadataPage from 'pages/MetadataPage';
import StaticContentPage from 'pages/StaticContentPage';
import CreateCatalogPage from 'pages/CreateCatalogPage';
import UserOptionsPage from 'pages/UserOptionsPage';
import PageTitle from 'components/PageTitle';
import PageNavigatorButtons from 'components/PageNavigatorButtons';
import UserOptions from 'components/UserOptions';
import 'CatalogHubApp.scss';
import { injectIntl, FormattedMessage } from 'react-intl';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { findAppVersion } from 'utils/dom';
import { isNullOrUndefined } from 'utils/object';
import { ArcGISPortal } from 'data-catalog-js-api';
import { loadModules } from 'esri-loader';

class CatalogHubApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            approved: false,
            loading: true
        };
        this.scrollTopIcon = React.createRef();
        this.headerBar = React.createRef();
        // Disable caching in-memory for calls to catalog services
        ArcGISPortal.ALLOW_MEMORY_CACHE = false;
    }

    componentWillMount() {
        const { id, authId, portalUrl, portalType, portalHome, token, tokenExpiry, user, intl } = this.props;
        hubStore.dispatch({
            type: 'SET_HUB_APP_SETTINGS',
            appId: id || '',
            appAuthId: authId || '',
            portalUrl: portalUrl || '',
            portalType: portalType || '',
            portalHome: portalHome || '',
            token: token || '',
            tokenExpiry: !isNullOrUndefined(tokenExpiry) ? tokenExpiry : -1,
            user: user || {}
        });
        loadModules([`esri/identity/IdentityManager`], { css: true })
            .then(([esriId]) => {
                esriId.on('dialog-create', () => {
                    esriId.dialog.when(() => {
                        esriId.dialog.content.messages.info = intl.formatMessage({
                            id: 'dialog.auth.message',
                            defaultMessage: "Please sign in to access data from '{server}'."
                        });
                    });
                });
                if (token !== undefined && token !== null && token !== '' && esriId !== undefined) {
                    esriId.registerToken({
                        server: portalUrl,
                        token: token
                    });
                }
                hubStore.dispatch({
                    type: 'SET_APPLICATION_STATE',
                    tokenManager: esriId
                });
            })
            .catch((idErr) => {
                console.log(idErr);
            })
            .finally(() => {
                if (!isNullOrUndefined(id) && !isNullOrUndefined(token) && token !== '') {
                    fetch(`${portalUrl.replace(/\/$/, '')}/content/items/${id}?f=json&token=${token}`).then(
                        (itemRsp) => {
                            itemRsp.json().then((itemInfo) => {
                                const okToGo = !itemInfo.error && itemInfo.id && itemInfo.id === id,
                                    userOpts = hubStore.getState().userOptions,
                                    userAppOpts = !isNullOrUndefined(userOpts)
                                        ? userOpts.find((o) => o.id === 'app')
                                        : null;
                                this.setState({
                                    approved: okToGo,
                                    loading: false,
                                    options: userAppOpts
                                });
                            });
                        }
                    );
                }
            });
    }

    componentDidMount() {
        if (this.scrollTopIcon && this.scrollTopIcon.current) this.scrollTopIcon.current.style.display = 'none';
        window.addEventListener('scroll', this.handleWindowScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleWindowScroll);
    }

    handleWindowScroll = (scrollEvt) => {
        if (this.scrollTopIcon.current && this.headerBar.current) {
            const activeScroll = document.scrollingElement.scrollTop;
            const headerOffset = window.outerHeight; //this.headerBar.current.getBoundingClientRect().height;
            this.scrollTopIcon.current.style.display = activeScroll < headerOffset ? 'none' : 'block';
        }
    };

    resetWindowScroll() {
        window.scrollTo(0, 0);
    }

    signOutOfApp = () => {
        const { id, authId, portalUrl, portalType, portalHome } = this.props;
        // 1st app load - set some globals (peristed in sessionStorage)
        hubStore.dispatch({
            type: 'SET_HUB_APP_SETTINGS',
            appId: id || '',
            appAuthId: authId || '',
            portalUrl: portalUrl || '',
            portalType: portalType || '',
            portalHome: portalHome || '',
            token: '',
            tokenExpiry: -1,
            user: null
        });
        // 1st app load - clear some page state (not persisted)
        hubStore.dispatch({
            type: 'SET_PAGE_STATE',
            custom: null
        });
        window.setTimeout(() => {
            window.location.href =
                (this.props.basename || process.env.PUBLIC_URL || '/').split('#')[0].split('?')[0] +
                '?action=sign-out&t=' +
                new Date().getTime().toFixed(0).substring(0, 12);
        }, 250);
    };

    render() {
        const { token, user, basename, queryString, appLinks, inErrorState, ...others } = this.props,
            { options } = this.state,
            anon = isNullOrUndefined(token) || token.length < 10 || isNullOrUndefined(user),
            appOuterClass = !anon ? 'auth' : 'anon',
            appSkinClass = !isNullOrUndefined(options) ? options.skin : 'normal',
            appWrapper = this,
            landingPageComponent = inErrorState ? AppCannotStartPage : HomePage,
            appbase =
                typeof process !== 'undefined' &&
                typeof process.env !== 'undefined' &&
                typeof process.env.PUBLIC_URL !== 'undefined'
                    ? process.env.PUBLIC_URL
                    : basename;

        return (
            <DragDropContextProvider backend={HTML5Backend}>
                <Provider store={hubStore}>
                    <BrowserRouter basename={appbase} queryString={queryString} forceRefresh={false} keyLength={24}>
                        <div
                            id="iaAppRoot"
                            className={`ia-app skin-${appSkinClass} ${appOuterClass}`}
                            ref={this.headerBar}
                        >
                            <Switch>
                                <RouteWithLayout
                                    exact
                                    path="/"
                                    component={landingPageComponent}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    {...others}
                                />
                                <RouteWithLayout
                                    exact
                                    path="/metadata/:indicatorId([a-zA-Z0-9\_\-]+)/:instanceId([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    component={MetadataPage}
                                    app={appWrapper}
                                    className="child-page catalog-metadata-page auth"
                                />
                                <RouteWithLayout
                                    exact
                                    path="/metadata/:indicatorId([a-zA-Z0-9\_\-]+)(\?.*)?"
                                    component={MetadataPage}
                                    app={appWrapper}
                                    className="child-page catalog-metadata-page auth"
                                />
                                <RouteWithLayout
                                    path="/new-catalog"
                                    component={CreateCatalogPage}
                                    app={appWrapper}
                                    className="child-page catalog-creator-page"
                                />
                                <RouteWithLayout
                                    path="/manager"
                                    component={ManagerPage}
                                    app={appWrapper}
                                    className="child-page catalog-manager-page"
                                />
                                <RouteWithLayout
                                    path="/inspector"
                                    component={InspectorPage}
                                    app={appWrapper}
                                    className="child-page catalog-inspector-page"
                                />
                                <RouteWithLayout
                                    path="/web-map-builder"
                                    component={WebMapBuilderPage}
                                    app={appWrapper}
                                    className="child-page web-map-builder-page"
                                />
                                <RouteWithLayout
                                    path="/explorer"
                                    component={DataExplorerPage}
                                    app={appWrapper}
                                    className="child-page catalog-explorer-page"
                                />
                                <Route exact path="/help" render={() => <Redirect to="/help/welcome" />} />
                                <RouteWithLayout
                                    path="/help/:id?"
                                    component={(props) => {
                                        const wwwUrl = `https://help.instantatlas.com/instantatlas-data-catalog/${props.location.pathname
                                            .replace('/help/welcome', '')
                                            .replace('/help/', '')}`;
                                        return (
                                            <StaticContentPage {...props} redirectIfEmpty={wwwUrl}>
                                                <p className="help-online-footer-link">
                                                    <span className="small">
                                                        <FormattedMessage
                                                            id="help.wwwLink"
                                                            defaultMessage="The latest version of this help page is available online @{link}."
                                                            values={{
                                                                link: (
                                                                    <a href={wwwUrl} target="iaHelpWindow">
                                                                        {wwwUrl}
                                                                    </a>
                                                                )
                                                            }}
                                                        />
                                                    </span>
                                                </p>
                                            </StaticContentPage>
                                        );
                                    }}
                                    app={appWrapper}
                                    className="child-page catalog-help-page"
                                />
                                <RouteWithLayout
                                    path="/user-options"
                                    component={UserOptionsPage}
                                    app={appWrapper}
                                    className="child-page user-options-page"
                                />
                                <RouteWithLayout
                                    component={noMatch(HomePage)}
                                    app={appWrapper}
                                    appLinks={appLinks}
                                    {...others}
                                />
                            </Switch>
                            <a
                                href="#iaAppRoot"
                                ref={this.scrollTopIcon}
                                onClick={() => {
                                    document.getElementById('iaAppRoot').scrollIntoView({ behavior: 'smooth' });
                                }}
                                className="toTopLink nodef"
                            >
                                <i className="fas fa-chevron-circle-up"></i>
                            </a>
                        </div>
                    </BrowserRouter>
                </Provider>
            </DragDropContextProvider>
        );
    }
}

const RouteWithLayout = ({ component: Component, app: appWrapper, className: routeClassName, ...rest }) => {
    const rootPath = (
            typeof process !== 'undefined' &&
            typeof process.env !== 'undefined' &&
            typeof process.env.PUBLIC_URL !== 'undefined'
                ? process.env.PUBLIC_URL
                : '/'
        ).replace(/(.*)\/$/, '$1'),
        activePath = { ...rest }.path,
        rootHelpPath =
            !isNullOrUndefined(appWrapper.props) &&
            !isNullOrUndefined(appWrapper.props.portalType) &&
            appWrapper.props.portalType.toLowerCase() !== 'online'
                ? `${rootPath}/help${activePath}`
                : `https://help.instantatlas.com/instantatlas-data-catalog${activePath}`,
        appInDev =
            typeof process !== 'undefined' &&
            typeof process.env !== 'undefined' &&
            !isNullOrUndefined(process.env.NODE_ENV) &&
            process.env.NODE_ENV === 'development',
        appVersion =
            typeof process !== 'undefined' &&
            typeof process.env !== 'undefined' &&
            process.env.REACT_APP_VERSION !== undefined
                ? process.env.REACT_APP_VERSION
                : findAppVersion('2.3');
    return (
        <Route
            {...rest}
            render={(matchProps) => (
                <div className={`page ${routeClassName !== undefined ? routeClassName : ''}`.trim()}>
                    <nav className="navbar navbar-default navbar-fixed-top">
                        <div className="container-fluid">
                            <div className="navbar-header">
                                <button
                                    type="button"
                                    className="navbar-toggle"
                                    data-toggle="collapse"
                                    data-target=".navbar-collapse"
                                >
                                    <i className="fas fa-fw fa-bars"></i>
                                </button>
                                <Link className="navbar-brand" to="/">
                                    <div
                                        className="about-link pure-tip pure-tip-bottom-right"
                                        data-tooltip={appWrapper.props.intl.formatMessage({
                                            id: 'app.label.hub',
                                            defaultMessage: 'InstantAtlas™ | Data Catalog | Hub'
                                        })}
                                    >
                                        <img
                                            alt="InstantAtlas Logo"
                                            src={rootPath + '/static/images/InstantAtlas-152px.jpg'}
                                            className="navbar-brand-icon"
                                        />
                                    </div>
                                </Link>
                            </div>
                            <div className="navbar-collapse collapse">
                                <ul className="nav navbar-nav navbar-left authContent">
                                    {activePath !== undefined &&
                                    activePath !== null &&
                                    activePath.substring(0, 5) !== '/help' ? (
                                        <li>
                                            <a href={rootHelpPath} target="iaHelpWindow">
                                                <FormattedMessage
                                                    id="app.link.help"
                                                    defaultMessage="{icon} Help &amp; Support"
                                                    values={{
                                                        icon: <i className="far fa-fw fa-question-circle"></i>
                                                    }}
                                                />
                                            </a>
                                        </li>
                                    ) : null}
                                    <li className="divider"></li>
                                    <UserOptions signOutAction={appWrapper.signOutOfApp} showOptions={false} />
                                </ul>
                                <ul
                                    className="nav navbar-nav navbar-right auth-content"
                                    style={{ marginRight: '4.5em' }}
                                ></ul>
                            </div>
                        </div>
                    </nav>
                    <header className={`app-header ${routeClassName !== undefined ? 'thinner' : ''}`.trim()}>
                        <div className="banner">
                            <div className="bannerBackdrop">&nbsp;</div>
                            <div className="bannerCentral banner-deep">
                                <div className="pull-right" style={{ display: 'none' }}>
                                    <a href="https://www.instantatlas.com/about/" target="iaoWindow">
                                        <img
                                            src={`${rootPath}/static/images/ArcGIS_Online_Specialty_Small-DarkBackground.png`}
                                            alt="ArcGIS Online Specialty Logo"
                                            style={{ display: 'block' }}
                                        />
                                    </a>
                                </div>
                                <div className="iao-wave">
                                    <h1>
                                        <span>
                                            <span className="ia-name">&nbsp;</span>
                                            <span className="app-name">
                                                <span className="product-name">
                                                    InstantAtlas<span className="tm">™</span>
                                                </span>
                                                <span className="builder-name">
                                                    <PageTitle updateDocument={true} />
                                                    <PageTitle textKey="group" icon="" className="customer-name" />
                                                    <PageTitle textKey="item" icon="" className="map-name" />
                                                </span>
                                            </span>
                                        </span>
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="app-body">
                        <main role="main">
                            <Component {...matchProps} {...rest} />
                            <PageNavigatorButtons />
                        </main>

                        <div className="container-fluid body-content simple-central">
                            <footer>
                                <div className="iaoFooter">
                                    <div>
                                        <div
                                            className="pull-right pure-tip pure-tip-top"
                                            data-tooltip={`Version ${appVersion}`}
                                            data-toggle="tooltip"
                                        >
                                            © ESRI (UK) Limited {new Date().getFullYear().toFixed(0)}
                                        </div>
                                        <div className="pull-left">
                                            <Link to="/">
                                                <FormattedMessage
                                                    id="app.label.hub"
                                                    defaultMessage="InstantAtlas™ | Data Catalog | Hub"
                                                />
                                                &nbsp;
                                                <FormattedMessage
                                                    id="app.label.version"
                                                    defaultMessage="{version}"
                                                    values={{ version: appVersion.split('.').slice(0, 2).join('.') }}
                                                />
                                                &nbsp;
                                                {appInDev ? <GitBranchComponent /> : null}
                                            </Link>
                                            <span>&nbsp;•&nbsp;</span>
                                            <a
                                                href={`${rootPath}/static/InstantAtlas-terms-and-conditions-for-cloud-services.pdf`}
                                                target="iaHelpWindow"
                                            >
                                                <FormattedMessage id="app.link.terms" defaultMessage="Terms" />
                                                &nbsp;
                                            </a>
                                        </div>
                                        <div className="cb"></div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                </div>
            )}
        />
    );
};

const noMatch = (WrappedComponent) => {
    return class NoMatch extends React.PureComponent {
        render() {
            const { location } = this.props;
            return (
                <div>
                    <div className="simple-central warning-disappear-after-15">
                        <div>
                            <h4>
                                <FormattedMessage
                                    id="notfound.title"
                                    defaultMessage="{icon} Page Not Found"
                                    values={{
                                        icon: <i className="fas fa-fw fa-exclamation-triangle"></i>,
                                        path: <code>{location.pathname}</code>
                                    }}
                                />
                            </h4>
                            <p>
                                <FormattedMessage
                                    id="notfound.label"
                                    defaultMessage="InstantAtlas could not find a page at {path}. You have been redirected to the {home}."
                                    values={{
                                        path: <code>{location.pathname}</code>,
                                        home: (
                                            <Link to="/">
                                                <FormattedMessage
                                                    id="notfound.link.home"
                                                    defaultMessage="Data Catalog Hub home page"
                                                />
                                            </Link>
                                        )
                                    }}
                                />
                            </p>
                        </div>
                    </div>
                    <WrappedComponent {...this.props} />
                </div>
            );
        }
    };
};

class GitBranchComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            branch: '?'
        };
    }

    componentDidMount() {
        fetch(`${process.env.PUBLIC_URL}/git-branch.txt`).then((gbRsp) => {
            gbRsp.text().then((text) => {
                this.setState({
                    branch: text
                });
            });
        });
    }

    render() {
        return (
            <em
                className="git-branch-name pure-tip pure-tip-top"
                style={{
                    whiteSpace: 'nowrap'
                }}
                data-tooltip="in-dev@git-branch"
            >
                {' '}
                <i className="fas fa-code-branch"></i> {this.state.branch}
            </em>
        );
    }
}

export default injectIntl(CatalogHubApp);
