import React, { Component } from 'react';
import { connect } from 'react-redux';
import ArcGISUtils from 'utils/arcgis';
//import { addClass, hasClass, removeClass } from 'utils/dom';
import { isNullOrUndefined } from 'utils/object';

// Class to implement a legend and layer list wrapper around the ArcGIS JS API objects,
// because none of them are good enough by themselves! It skirts around some React/ArcGIS quirks by directly
// interacting with the DOM and the map (which is not very React-y) but makes life simpler.
class ArcWebMapLayerList extends Component {
    constructor(props) {
        super(props);
        this._arcGisUtils = new ArcGISUtils();
    }

    componentDidMount() {
        this.rebuildLegends();
    }

    componentWillUnmount() {
        if (this._legend !== undefined && this._legend !== null) this._legend.destroy();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const hasOldMap = !isNullOrUndefined(this.props.view),
            hasNewMap = !isNullOrUndefined(nextProps.view),
            hasOldLayers = !isNullOrUndefined(this.props.layers),
            hasNewLayers = !isNullOrUndefined(nextProps.layers),
            updateRequired =
                hasOldMap !== hasNewMap ||
                this.props.view !== nextProps.view ||
                hasOldLayers !== hasNewLayers ||
                (hasOldLayers &&
                    hasNewLayers &&
                    JSON.stringify(this.props.layers) !== JSON.stringify(nextProps.layers)); // ||
        //(hasOldMap && hasNewMap && (this.props.map.id !== nextProps.map.id));// ||
        //(hasOldMap && hasNewMap && (this.props.map.graphicsLayerIds !== nextProps.map.graphicsLayerIds)); // || super.shouldComponentUpdate(nextProps, nextState, nextContext);
        return updateRequired;
    }

    componentWillUpdate() {
        //this.rebuildLegend();
    }

    componentDidUpdate() {
        this.rebuildLegends(true);
        if (!isNullOrUndefined(this._legend)) {
            setTimeout(() => {
                mapZoomHandler(this.rootNode, this._legend.view); // Manual trigger
            }, 500);
        }
    }

    rebuildLegends = (force = false) => {
        // Fetch objects from properties - note that "map" is an actual ArcGIS JS Map object, not a definition of it as it is in some other classes
        const { user, portalType, view } = this.props,
            destructionRequired =
                !isNullOrUndefined(this._legend) && !isNullOrUndefined(view) && this._legend.view.id !== view.id,
            layerNodes = this.rootNode.querySelectorAll('.list-group-item.layer-item'),
            legendNodes = this.rootNode.querySelectorAll('.list-group-item.layer-item .iao-arc-legend > .esri-legend'),
            creationRequired =
                force || destructionRequired || (!isNullOrUndefined(view) && layerNodes.length !== legendNodes.length);
        if (destructionRequired) {
            this._legend.destroy();
            // Legend.destroy() is ugly - it has a lag, it doesn't clear up properly all sorts, so...
            if (!isNullOrUndefined(this.legendNode.firstElementChild))
                this.legendNode.removeChild(this.legendNode.firstElementChild);
            // And clean up any event listeners too
            if (!isNullOrUndefined(this._mapZoomHandler)) this._mapZoomHandler.remove();
            this._mapZoomHandler = null;
        }

        if (user !== null && user.username !== undefined && user.username !== null && creationRequired) {
            const options = portalType.toLowerCase() === 'online' ? { version: '4.16' } : { version: '4.16' }; // TODO - update
            this._arcGisUtils
                .init(options)
                .then((arcUtils) => {
                    const { view } = this.props,
                        watchUtils = arcUtils.modules[arcUtils.modules.length - 1],
                        layerNodes = this.rootNode.querySelectorAll('.list-group-item.layer-item');
                    for (let ln of layerNodes) {
                        const lyrId = ln.getAttribute('data-layer-id'),
                            legNode = ln.querySelector('.iao-arc-legend > div'),
                            legId = legNode.getAttribute('id');
                        //legNode.innerText = null;
                        //console.log(`legend rebuid, layer ${lyrId}: ${view.map.findLayerById(lyrId)}`); // DEBUG
                        arcUtils
                            .createLegend(view, view.map.findLayerById(lyrId), null, null, '', legId, 250, true)
                            .then((legend) => {
                                if (legend !== null && legend.container !== undefined && legend.container !== null) {
                                    const activeLegId = legend.container.getAttribute('id');
                                    // Sigh - v4 of the API and this is still not right - why don't they return a promise if they can't clean up?!?!?
                                    ln.querySelectorAll(`.esri-legend:not([id="${activeLegId}"])`).forEach((n) =>
                                        n.remove()
                                    );
                                }
                            });
                    }
                    watchUtils.whenTrue(view, 'stationary', () => {
                        mapZoomHandler(this.rootNode, view);
                    });
                    mapZoomHandler(this.rootNode, view); // Manual trigger
                    // Detect the ones we will use...
                    /*arcUtils.createLegend(view, null, null, null, null, legendId)
                        .then(activeLegend => 
                        {
                            this._legend = activeLegend;
                            
                        });*/
                })
                .catch((err) => {
                    // handle any script or module loading errors
                    console.error(err);
                });
        }
    };

    toggleMapLayer = (evt) => {
        const { onLayerChange, view } = this.props,
            lyrId = evt.target.value,
            lyrVis = evt.target.checked;
        if (!isNullOrUndefined(view)) {
            const lyr = view.map.findLayerById(lyrId);
            if (!isNullOrUndefined(lyr)) lyr.visible = lyrVis; //lyr.setVisibility(lyrVis);
            if (!isNullOrUndefined(onLayerChange)) onLayerChange(lyrId, lyr);
        }
    };

    deleteMapLayer = (lyrId) => {
        const { onDeleteLayer, view } = this.props;
        if (!isNullOrUndefined(view)) {
            const lyr = view.map.findLayerById(lyrId);
            if (!isNullOrUndefined(lyr)) {
                view.map.layers.remove(lyr);
                if (!isNullOrUndefined(onDeleteLayer)) onDeleteLayer(lyrId, lyr);
                this.forceUpdate();
                this.rebuildLegends(true);
            }
        }
    };

    render() {
        const { view, layers, layersArePrimary = false } = this.props,
            layerIdSet =
                layersArePrimary && !isNullOrUndefined(layers)
                    ? layers.map((lyr) => lyr.id)
                    : !isNullOrUndefined(view) && !isNullOrUndefined(view.map.layers)
                    ? view.map.layers.items.map((lyr) => lyr.id)
                    : null,
            findInLayers = (lyrList, id) => {
                return lyrList.find((i) => i.id === id);
            },
            layerList = !isNullOrUndefined(layerIdSet)
                ? layerIdSet.map((lyrId, lyrIndex) => {
                      // This looks up map layers and operational layers to deal with the (lack of) synchronicity in map and legend updates *sigh*
                      const legendContainer = `mapLegend${lyrIndex}L${lyrId}`,
                          lyr = view.map.findLayerById(lyrId),
                          olyr =
                              !isNullOrUndefined(layers) && findInLayers(layers, lyrId) !== undefined
                                  ? findInLayers(layers, lyrId)
                                  : null;
                      if (
                          isNullOrUndefined(olyr) &&
                          (isNullOrUndefined(lyr) || isNullOrUndefined(lyr.type) || lyr.type !== 'Feature Layer')
                      )
                          return null;
                      const lyrTitle = !isNullOrUndefined(olyr) ? olyr.title : lyr.name,
                          lyrVis =
                              !isNullOrUndefined(lyr) && lyr.visible !== undefined
                                  ? lyr.visible
                                  : !isNullOrUndefined(olyr)
                                  ? olyr.visible || olyr.visibility
                                  : false,
                          lyrNotInMap = isNullOrUndefined(lyr);

                      return (
                          <li
                              key={lyrIndex}
                              id={`lyrCtrl_${lyrIndex}`}
                              data-layer-id={lyrId}
                              className="list-group-item layer-item"
                          >
                              <input
                                  type="checkbox"
                                  id={`lyrBox_${lyrIndex}`}
                                  value={lyrId}
                                  defaultChecked={lyrVis}
                                  disabled={lyrNotInMap}
                                  onChange={this.toggleMapLayer}
                                  className="form-control map-layer-toggle-control"
                              />
                              <label htmlFor={`lyrBox_${lyrIndex}`} className="control-label">
                                  {lyrTitle}
                              </label>
                              <button
                                  className="btn-link nodef fix-btm-right iao-delete-link"
                                  onClick={(e) => this.deleteMapLayer(lyr.id, e)}
                              >
                                  <i className="fas fa-fw fa-trash-alt"></i>
                              </button>
                              <div className="iao-arc-legend">
                                  <div id={legendContainer}></div>
                              </div>
                          </li>
                      );
                  })
                : null;

        return (
            <div
                ref={(e) => {
                    this.rootNode = e;
                }}
            >
                <div className="arc-layer-list">{layerList !== null ? <ul>{layerList}</ul> : null}</div>
                <div
                    ref={(c) => {
                        this.legendNode = c;
                    }}
                    className="arcLegend"
                    style={{ width: 'auto', height: 'auto', display: 'none' }}
                />
            </div>
        );
    }
}

const mapZoomHandler = (r, mapView, ze, attempts) => {
    const lyrToggleList = r.getElementsByClassName('map-layer-toggle-control'),
        vis = getLayersVisibleAtCurrentScale(mapView), //.getLayersVisibleAtScale(agoMap.scale),
        previous = typeof attempts != 'undefined' ? attempts : 0,
        findVis = (lyrId) => {
            const lyr = vis.find((alyr) => alyr.id === lyrId);
            return lyr;
        };
    Array.from(lyrToggleList).forEach((box) => {
        box.setAttribute('disabled', 'disabled');
        box.removeAttribute('checked');
        const lyr = findVis(box.getAttribute('value'));
        if (!isNullOrUndefined(lyr)) {
            box.removeAttribute('disabled');
            if (lyr.visible) box.setAttribute('checked', 'checked');
        }
    });
    setTimeout(() => {
        const lyrItemList = r.getElementsByClassName('list-group-item'),
            legendRoot = r.getElementsByClassName('arcLegend').item(0).firstElementChild;
        if (!isNullOrUndefined(legendRoot)) {
            const legendRootId = legendRoot.getAttribute('id');
            let unfetched = 0;
            Array.from(lyrItemList).forEach((li) => {
                //if (!hasClass(li, 'legend-loaded'))
                //{
                const lid = li.getAttribute('data-layer-id'),
                    legendBlock = legendRoot.ownerDocument.getElementById(`${legendRootId}_${lid}`),
                    legendTable = !isNullOrUndefined(legendBlock)
                        ? legendBlock.getElementsByClassName('esriLegendLayer').item(1)
                        : null, // 1st item is the header, so index = 1, not 0
                    legendOld = li.getElementsByClassName('esriLegendLayer').item(0);
                if (!isNullOrUndefined(legendTable)) {
                    if (!isNullOrUndefined(legendOld)) li.removeChild(legendOld);
                    li.appendChild(legendTable); // Should we clone this?
                    //addClass(li, 'legend-loaded');
                } else unfetched++;
                //}
            });
            if (unfetched > 0 && previous < 5) mapZoomHandler(r, mapView, ze, previous + 1);
        }
    }, 100 + previous * 100); // In case the legend takes a while...
};

const getLayersVisibleAtCurrentScale = (view) => {
    const s = view.scale,
        lyrs = view.map.layers.filter((lyr) => {
            return (
                (lyr.minScale === 0 && lyr.maxScale === 0) ||
                (lyr.minScale > s && (lyr.maxScale === 0 || lyr.maxScale < s)) ||
                (lyr.maxScale < s && (lyr.minScale === 0 || lyr.minScale > s))
            );
        });
    return lyrs;
};

const mapStateToProps = (state) => {
    return {
        token: state.hubAppSettings.token,
        portalUrl: state.hubAppSettings.portalUrl,
        portalType: state.hubAppSettings.portalType,
        appAuthId: state.hubAppSettings.appAuthId,
        user: state.hubAppSettings.user
    };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(ArcWebMapLayerList);
