import React, { Component } from 'react';
import { setPageState, setUserOptions } from 'redux/hubStore';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import ThemeTreePanel from 'components/ReduxThemeTreePanel';
import { isNullOrUndefined, lowerKeyParams } from 'utils/object';
import { findCatalogTable } from 'api/catalogUtils';
import { hasClass, addClass, removeClass } from 'utils/dom';
import { ArcGISPortal, DataCatalogManager } from 'data-catalog-js-api';
import ListToggleButton from 'components/ListToggleButton';
import ItemsLinkList from 'components/ItemsLinkList';
import ProgressMessage from 'components/ProgressMessage';
import MetadataDialog from 'components/manager/MetadataDialog';
import ChooseArcItemDialog from 'components/ChooseArcItemDialog';
import ArcItemSaveDialog from 'components/ArcItemSaveDialog';
import ModalDialog from 'components/ModalDialog';
import ArcWebMap from 'components/ArcWebMap';
import ArcWebMapLayerList from 'components/ArcWebMapLayerList';
import 'pages/WebMapBuilder.scss';
import { PageActivityStatus } from 'pages/pageConstants';
import ArcGISUtils from 'utils/arcgis';
import { Promise } from 'q';
import { getBestToken } from 'utils/auth';

class WebMapBuilderPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: PageActivityStatus.LOADING_CATALOG,
            error: null,
            messages: [],
            catalogs: [],
            userOnly: false,
            activeModal: null, // Simple lookup by ID/key to show one modal at a time - this page is the controller of what is showing
            activeModalProps: null, // Deliberately vague set of properties that can be passed to each modal - no real type checking but...
            view: 'default', // default|expanded
            hideEmptyFolders: true,
            activeTab: 'dataExplorer',
            initialMapDef: JSON.stringify(ArcGISUtils.getVanillaWebMap()), // The map definition we start with - may be a vanilla map or it may be loaded directly - passed to ArcWebMap on each render()
            activeMapDef: null, // The in-memory map, which can be adjusted by actions but is NOT transferred to the ArcWebMap object on each render()
            enableScaleButton: true
        };
        this.toggleButton = React.createRef();
        this.optionsButton = React.createRef();
        this.webMap = React.createRef();
        this.webMapMask = React.createRef();
        this.webMapLegend = React.createRef();
    }

    get defaultPageOptions() {
        return {
            id: 'webMapBuilderPage',
            map: {
                scales: true,
                oneDatePerLayer: false,
                proportionalCircles: true
            }
        };
    }

    componentDidMount() {
        const { setPageState, user } = this.props;
        setPageState('Data Catalog | Web Map Builder', this.getTitleIcon(), null, null);
        const qs = lowerKeyParams(new URLSearchParams(window.location.search));
        if (user !== null && user.username !== undefined && user.username !== null) {
            this.loadMasterTableBasics(
                qs['item'],
                null,
                null,
                null,
                false,
                qs['webmap'],
                !isNullOrUndefined(qs['public']) && '1,true,yes'.indexOf(qs['public']) >= 0
            );
        }
    }

    getTitleIcon() {
        return (
            <span className="ia-page-icon">
                <i className="fas fa-map-marked-alt"></i>
                <i className="fas fa-hammer fa-flip-horizontal"></i>
            </span>
        );
    }

    hideModal = () => {
        this.setState({
            activeModal: null
        });
    };

    clearError = () => {
        this.setState({
            activeModal: null,
            error: null
        });
    };

    showMetadataDialog = (evt) => {
        const trigger = evt.target,
            li = trigger.closest('.ind'),
            iid = li.getAttribute('data-ind-uuid'),
            iname = li.getAttribute('data-ind-name');
        this.setState({
            activeModal: 'metadataDialog',
            activeModalProps: {
                indicator: {
                    id: iid,
                    name: iname
                }
            }
        });
    };

    showWebMapChooserDialog = (evt) => {
        this.setState({
            activeModal: 'arcChooseMapDialog',
            activeModalProps: {}
        });
    };

    setActiveTab = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        const trigger = evt.target,
            tab = trigger.getAttribute('href');
        if (!isNullOrUndefined(tab)) {
            this.setState({
                activeTab: tab.substring(1)
            });
        }
    };

    onCatalogChange = (evt, url, itemId) => {
        const { user } = this.props,
            { catalogs } = this.state;
        this.setState(
            {
                status: PageActivityStatus.LOADING_CATALOG,
                catalog: null,
                error: null
            },
            () => {
                const c = catalogs.find((dc) => dc.id === itemId),
                    isLikelyPublic = c.url.indexOf(user.orgId) < 0;
                this.loadMasterTableBasics(itemId, null, null, null, this.state.userOnly, null, isLikelyPublic); // Clear the catalog = clear the webmap
            }
        );
    };

    onGeoChange = (evt, geoId, activeState) => {
        const { catalog, geo } = this.state,
            activeGeo = !isNullOrUndefined(geoId) && activeState ? geoId : evt.target.value;
        // Update needed?
        if (activeGeo !== geo) {
            this.setState(
                {
                    status: PageActivityStatus.LOADING_CATALOG,
                    geo: activeGeo
                },
                () => {
                    this.rebindModelFromCatalog(catalog, activeGeo, null, false);
                }
            );
        }
    };

    onOwnerListChange = (evt) => {
        const { userOnly, table } = this.state;
        if (!isNullOrUndefined(table)) this.loadMasterTableBasics(table.id, null, null, null, !userOnly, null); // Force an update - ugly but...
    };

    loadMasterTableBasics = (
        itemId,
        itemInfo,
        tableItems = null,
        listHtml = null,
        ownedByCurrentUser = false,
        webMapId = null,
        includePublicCatalogs = false
    ) => {
        const { user, portalUrl, portalHome, token } = this.props;
        if (isNullOrUndefined(itemId) || isNullOrUndefined(itemInfo)) {
            findCatalogTable({
                home: portalUrl,
                user: user,
                token: token,
                table: itemId,
                allowViewOnly: true,
                public: includePublicCatalogs,
                max: includePublicCatalogs ? 50 : -1
            }).then((catalogItems) => {
                const tableItem = catalogItems.table,
                    //mapItem = catalogItems.map,
                    possibles = catalogItems.available,
                    listHtml = catalogItems.html;
                if (tableItem !== undefined && tableItem !== null)
                    this.loadMasterTableBasics(
                        tableItem.id,
                        tableItem,
                        possibles,
                        listHtml,
                        ownedByCurrentUser,
                        webMapId
                    );
                else {
                    const errMsg = !isNullOrUndefined(itemId) ? (
                        <FormattedMessage
                            id="webmapbuilder.invalidItemDialog.messageFormat"
                            defaultMessage="You cannot use {item} with the Web Map Builder - either you do not have access or it cannot be found. Please contact {email} for more information."
                            values={{
                                item: <strong>{itemId}</strong>,
                                email: <a href="mailto:support@instantatlas.com">support@instantatlas.com</a>
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="webmapbuilder.noValidItemsDialog.messageFormat"
                            defaultMessage="No suitable catalog items were found on {portal}. Web Map Builder cannot start. Sometimes this is caused by temporary network issues - you could try to {reload}. If this error is persistent please contact {email} for more information."
                            values={{
                                email: <a href="mailto:support@instantatlas.com">support@instantatlas.com</a>,
                                portal: (
                                    <a href={portalHome} target="iaoArcWindow">
                                        {portalHome} <i className="fas fa-external-link-alt"></i>
                                    </a>
                                ),
                                reload: (
                                    <a
                                        href="?action=reload"
                                        onClick={() => {
                                            window.location.reload();
                                            return false;
                                        }}
                                    >
                                        <FormattedMessage
                                            id="manager.noValidItemsDialog.button.reload"
                                            defaultMessage="Reload {icon}"
                                            values={{
                                                icon: <i className="fas fa-sync"></i>
                                            }}
                                        />
                                    </a>
                                )
                            }}
                        />
                    );

                    this.setState(
                        {
                            status: PageActivityStatus.INACTIVE,
                            error: {
                                message: errMsg,
                                key: 'NoMasterTable',
                                buttons: [
                                    <Link to="/" className="btn btn-default btn-secondary">
                                        <FormattedMessage
                                            id="explorer.errorDialog.button.close"
                                            defaultMessage="{icon} Cancel"
                                            values={{
                                                icon: <i className="fas fa-times"></i>
                                            }}
                                        />
                                    </Link>
                                ]
                            },
                            catalogs: possibles
                        },
                        () => {
                            this.toggleButton.current.setState({
                                on: false
                            });
                            window.history.pushState('', '', '?#reset');
                            //this.onCatalogChange(null, null, null);
                        }
                    );
                }
            });
        } else {
            const activeTableItems =
                ownedByCurrentUser && !isNullOrUndefined(tableItems)
                    ? tableItems.filter((t) => t.owner === user.username)
                    : tableItems;
            this.props.setPageState('Data Catalog | Web Map Builder', this.getTitleIcon(), null, {
                id: itemInfo.id,
                text: null // No display here...
            }); // <span>{itemInfo.title} <a href={`https://www.arcgis.com/home/item.html?id=${itemId}`} target="iaoArcWindow"><i className="fas fa-external-link-alt" style={{ fontSize: '0.9em' }}></i></a></span>
            this.setState(
                {
                    status: PageActivityStatus.LOADING_CATALOG,
                    error: null,
                    table: itemInfo,
                    messages: [],
                    progress: 0,
                    catalogs: activeTableItems,
                    userOnly: ownedByCurrentUser,
                    initialMapDef: JSON.stringify(ArcGISUtils.getVanillaWebMap())
                },
                () => {
                    window.history.pushState(
                        '',
                        '',
                        `?item=${itemInfo.id}${includePublicCatalogs ? '&public=yes' : ''}`
                    );
                    const catalog = new DataCatalogManager(itemInfo, null, user, token, portalUrl, (err) => {
                        this.relayError(err, itemInfo);
                    });
                    catalog.init().then(() => {
                        this.setState(
                            {
                                catalog: catalog,
                                status: PageActivityStatus.LOADING_CATALOG
                            },
                            () => {
                                this.rebindModelFromCatalog(catalog, null, webMapId, true);
                            }
                        );
                    });
                }
            );
        }
    };

    relayError = (err, itemInfo) => {
        const { portalType } = this.props;
        this.setState({
            status: PageActivityStatus.INACTIVE,
            error: {
                title: (
                    <FormattedMessage
                        id="webmapbuilder.errorDialog.title"
                        defaultMessage="Unexpected Error"
                        values={{
                            name: <strong>{err.code}</strong>
                        }}
                    />
                ),
                message: (
                    <FormattedMessage
                        id="webmapbuilder.errorDialog.messageFormat"
                        tagName="div"
                        defaultMessage="InstantAtlas Web Map Builder encountered an unexpected error while connecting to ArcGIS {portal}{itemLink}. This can happen when a network error or slowdown occurs, or if your authentication has timed out (you can sign in on the {home}). Please {reload} and try again. The error message was: {message}"
                        values={{
                            portal: portalType,
                            message: (
                                <div>
                                    <br />
                                    <div className="bg-danger pad10">
                                        {err.code} {err.message}{' '}
                                        {!isNullOrUndefined(err.details) ? (
                                            <span>
                                                <br />
                                                {err.details}
                                            </span>
                                        ) : null}
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            ),
                            home: (
                                <Link to="/">
                                    <FormattedMessage
                                        id="webmapbuilder.errorDialog.homeLink"
                                        defaultMessage="Hub home page"
                                    />
                                </Link>
                            ),
                            reload: (
                                <strong>
                                    <a href={`${window.location.pathname}?#reset`}>
                                        <FormattedMessage
                                            id="webmapbuilder.errorDialog.reloadLink"
                                            defaultMessage="{icon} reload and reset this page"
                                            values={{
                                                icon: <i className="fas fa-sync"></i>
                                            }}
                                        />
                                    </a>
                                </strong>
                            ),
                            itemLink: isNullOrUndefined(itemInfo) ? null : (
                                <FormattedMessage
                                    id="webmapbuilder.errorDialog.itemLink"
                                    defaultMessage=" (for {arcLink})"
                                    values={{
                                        arcLink: (
                                            <a
                                                href={`https://www.arcgis.com/home/item.html?id=${itemInfo.id}`}
                                                target="iaoArcWindow"
                                            >
                                                {itemInfo.title}{' '}
                                                <i
                                                    className="fas fa-external-link-alt"
                                                    style={{ fontSize: '0.9em' }}
                                                ></i>
                                            </a>
                                        )
                                    }}
                                />
                            )
                        }}
                    />
                ),
                key: !isNullOrUndefined(err) && !isNullOrUndefined(err.code) ? err.code : 'FatalError',
                buttons: [
                    <a
                        href={`${window.location.pathname}?#reset`}
                        className="btn btn-primary"
                        onClick={this.clearError}
                    >
                        <FormattedMessage
                            id="webmapbuilder.errorDialog.button.reload"
                            defaultMessage="{icon} Reload"
                            values={{
                                icon: <i className="fas fa-sync"></i>
                            }}
                        />
                    </a>,
                    <Link to="/" className="btn btn-default btn-secondary">
                        <FormattedMessage
                            id="webmapbuilder.errorDialog.button.close"
                            defaultMessage="{icon} Cancel"
                            values={{
                                icon: <i className="fas fa-times"></i>
                            }}
                        />
                    </Link>
                ]
            }
        });
    };

    rebindModelFromCatalog = (catalog, geoId, webMapId = null, resetExistingMap = true) => {
        const { activeMapDef } = this.state,
            { portalUrl } = this.props,
            activeGeo = geoId !== undefined && geoId !== null ? geoId : catalog.master.geos[0]['ID'],
            webMapComponent = this.webMap.current; //.getWrappedInstance();
        catalog.getDataModel(activeGeo).then((dataModel) => {
            catalog
                .getExtent(activeGeo)
                .then((geoExtent) => {
                    let autoScalesAvailable = Promise.resolve(true);
                    // A simple geo change within the same catalog shouldn't reset the map...
                    if (resetExistingMap || !isNullOrUndefined(webMapId)) {
                        webMapComponent.clearMap();
                        webMapComponent.setMapExtent(geoExtent);
                        // Test the extent - might want to disable scale thresholds..
                        autoScalesAvailable = ArcGISUtils.extentsOverlap(geoExtent, {
                            xmin: -10.8544921875,
                            ymin: 49.82380908513249,
                            xmax: 2.021484375,
                            ymax: 59.478568831926395,
                            spatialReference: { wkid: 4326 }
                        });
                    }
                    autoScalesAvailable.then((canUseScales) => {
                        this.setState(
                            {
                                status: PageActivityStatus.INACTIVE,
                                model: dataModel,
                                geo: activeGeo,
                                mapExtent: geoExtent,
                                activeMapDef: resetExistingMap || !isNullOrUndefined(webMapId) ? null : activeMapDef,
                                enableScaleButton: canUseScales
                            },
                            () => {
                                if (!isNullOrUndefined(webMapId)) {
                                    ArcGISPortal.getItemDescription(
                                        webMapId,
                                        { f: 'json', token: catalog.token },
                                        portalUrl
                                    ).then((itemInfo) => {
                                        this.openWebMap(itemInfo.id, itemInfo);
                                    });
                                }
                            }
                        );
                    });
                })
                .catch((err) => {
                    this.relayError(err, null);
                });
        });
    };

    collectMap = (arcMapObject, arcUtils) => {
        // Hold onto these... not in state, we need to divorce this stuff from state to avoid (re)render()
        this._arcMapObject = arcMapObject;
        this._arcUtils = arcUtils;
        this.setState({
            activeMapDef: JSON.stringify(arcMapObject.item)
        });
    };

    handleMapError = (err) => {
        this.setState({
            activeMapDef: null,
            error: {
                message: (
                    <FormattedMessage
                        id="webmapbuilder.loadMap.arcErrorDialog.messageFormat"
                        tagName="div"
                        defaultMessage="An unexpected and serious error occurred when building the map. Please {reload} this page and try again. If this error is persistent please contact {email}. The error message was: {message}"
                        values={{
                            message: (
                                <div>
                                    <br />
                                    <div className="bg-danger pad10">
                                        {err.code} {err.message}{' '}
                                        {!isNullOrUndefined(err.details) ? (
                                            <span>
                                                <br />
                                                {err.details}
                                            </span>
                                        ) : null}
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            ),
                            home: (
                                <Link to="/">
                                    <FormattedMessage
                                        id="webmapbuilder.errorDialog.homeLink"
                                        defaultMessage="Hub home page"
                                    />
                                </Link>
                            ),
                            reload: (
                                <strong>
                                    <a href={`${window.location.pathname}?#reset`}>
                                        <FormattedMessage
                                            id="webmapbuilder.errorDialog.reloadLink"
                                            defaultMessage="{icon} reload and reset this page"
                                            values={{
                                                icon: <i className="fas fa-sync"></i>
                                            }}
                                        />
                                    </a>
                                </strong>
                            ),
                            email: (
                                <a href="mailto:support@instantatlas.com?subject=InstantAtlas%20Web%20Map%20Builder%20Error">
                                    support@instantatlas.com
                                </a>
                            )
                        }}
                    />
                ),
                key: 'ArcGISScriptError'
            }
        });
    };

    insertIndicatorIntoMap = (evt, options) => {
        const { geo } = this.state,
            trigger = evt.currentTarget,
            isAll = trigger.getAttribute('data-action') === 'add-all',
            li = trigger.closest('.ind'),
            iid = li.getAttribute('data-ind-uuid'),
            iname = li.getAttribute('data-ind-name'),
            itype = (li.getAttribute('data-ind-args') || 'number').split('|').pop(),
            indicatorGeoList = [{ indicator: iid, geo: isAll ? null : geo, label: iname, dataType: itype }];
        this.insertMultipleIndicatorsIntoMap(indicatorGeoList, options);
    };

    insertMultipleIndicatorsIntoMap = async (indicatorGeoList = [], options) => {
        const { match, userOptions, token, tokenManager } = this.props,
            { catalog, geo, table, activeMapDef, enableScaleButton } = this.state,
            pageOptions =
                !isNullOrUndefined(userOptions) && userOptions.find((o) => o.id === 'webMapBuilderPage') !== undefined
                    ? userOptions.find((o) => o.id === 'webMapBuilderPage')
                    : this.defaultPageOptions,
            opts =
                options !== undefined
                    ? options
                    : {
                          fieldLabel: '{0} ({1})',
                          popupTitle: '{0} | {2}',
                          scales: pageOptions.map.scales && enableScaleButton,
                          proportionalCircles: pageOptions.map.proportionalCircles,
                          excludes: ['G8', 'G9'],
                          chart: false,
                          metadataViewerUrl: `${window.location.protocol}//${window.location.hostname}${
                              window.location.port !== 80 ? ':' + window.location.port : ''
                          }${window.location.pathname.replace(match.path, '')}/metadata/`,
                          catalog: table.id,
                          webmap:
                              !isNullOrUndefined(activeMapDef) && activeMapDef !== '' ? JSON.parse(activeMapDef) : null,
                          token
                      },
            mask = this.webMapMask.current,
            single = indicatorGeoList.length === 1 && !isNullOrUndefined(indicatorGeoList[0].label),
            geoList = single ? indicatorGeoList[0].geo : indicatorGeoList.map((ig) => ig.geo),
            indList = single ? indicatorGeoList[0].indicator : indicatorGeoList.map((ig) => ig.indicator),
            iname = single ? indicatorGeoList[0].label : 'Indicators';

        let inameLookup = single
            ? [
                  {
                      ID: indicatorGeoList[0].indicator,
                      Name: indicatorGeoList[0].label,
                      Data_Type: indicatorGeoList[0].dataType
                  }
              ]
            : [];
        if (!isNullOrUndefined(mask) && !hasClass(mask, 'in')) addClass(mask, 'in');
        this.setState({
            status: PageActivityStatus.LOADING_MAP
        });
        const instanceSet = await (single
            ? catalog.getDates(indList, geoList, 'Date_ID,Item_Order DESC', true)
            : catalog.getIndicators(indList).then((indArray) => {
                  inameLookup = indArray.slice();
                  return catalog.getDatesForIndicatorsAndGeos(indList, geoList, 'Date_ID,Item_Order DESC', true);
              }));
        if (instanceSet.fields !== undefined) {
            const gif = instanceSet.fields.find((f) => f.name.toUpperCase() === 'GEO_ID').name,
                fif = instanceSet.fields.find((f) => f.name.toUpperCase() === 'FIELD_ID').name,
                nmf = instanceSet.fields.find((f) => f.name.toUpperCase() === 'NAME').name,
                uif = instanceSet.fields.find((f) => f.name.toUpperCase() === 'SERVICE_URL').name,
                pif = instanceSet.fields.find((f) => f.name.toUpperCase() === 'INDICATOR_ID').name;
            let geoIndicatorSets = [],
                gid,
                iid,
                geoMaster,
                indMaster,
                giSet;
            // Avoiding webpack no-loop-func
            const findInArrayById = (array, id) => {
                    return array.find((i) => i['ID'] === id);
                },
                findIndSet = (array, gid, iid) => {
                    // Sneaky use of the options - if they want one date per layer (many layers!) let them by never finding a match...
                    return pageOptions.map.oneDatePerLayer
                        ? undefined
                        : array.find((gs) => gs.geo.id === gid && gs.indicator.id === iid);
                };
            for (let i of instanceSet.features) {
                gid = i.attributes[gif];
                iid = i.attributes[pif];
                giSet = findIndSet(geoIndicatorSets, gid, iid);
                if (giSet === undefined) {
                    geoMaster = findInArrayById(catalog.master.geos, gid);
                    indMaster = findInArrayById(inameLookup, iid);
                    // Alow this to fail, in the rare case where you have an orphan indictaor
                    if (!isNullOrUndefined(geoMaster) && !isNullOrUndefined(indMaster)) {
                        giSet = {
                            geo: {
                                id: gid,
                                name: geoMaster[nmf]
                            },
                            instances: [],
                            url: i.attributes[uif].replace('http:', 'https:'),
                            indicator: {
                                id: iid,
                                name: indMaster[nmf],
                                dataType: indMaster['Data_Type'] || indMaster['DATA_TYPE'] || 'number'
                            }
                        };
                        //if (pageOptions.oneDatePerLayer) giSet.title =
                        giSet.instances.unshift({
                            alias: i.attributes[nmf],
                            field: i.attributes[fif]
                        });
                        geoIndicatorSets.push(giSet);
                    }
                } else {
                    giSet.instances.unshift({
                        alias: i.attributes[nmf],
                        field: i.attributes[fif]
                    });
                }
            }
            for (let gi of geoIndicatorSets) {
                gi.token = await getBestToken(gi.url, opts.token, tokenManager);
            }
            // Get the extent from the 1st one...
            const lyrExtent = await catalog.getExtent(geo);
            return this._arcUtils
                .createIndicatorMap(this._arcMapObject.view, geoIndicatorSets, iname, lyrExtent, opts)
                .then(
                    (updatedMapDef) => {
                        this.setState(
                            {
                                status: PageActivityStatus.INACTIVE,
                                activeMapDef: JSON.stringify(updatedMapDef)
                            },
                            () => {
                                if (!isNullOrUndefined(mask) && hasClass(mask, 'in')) removeClass(mask, 'in');
                                //this.webMapLegend.current.getWrappedInstance().forceUpdate();
                                //this.forceUpdate();
                            }
                        );
                    },
                    (err) => {
                        this.handleMapError(err);
                        if (!isNullOrUndefined(mask) && hasClass(mask, 'in')) removeClass(mask, 'in');
                    }
                );
        }
    };

    onUpdateLayerToggleChange = (lyrId) => {
        const { activeModalProps } = this.state,
            item = activeModalProps.find((lyr) => lyr.id === lyrId);
        if (item !== undefined) item.checked = !item.checked;
    };

    commitToIndicatorMapUpdate = () => {
        const { activeModalProps } = this.state,
            indicatorGeoList = activeModalProps.filter((lyr) => {
                return lyr.checked;
            }); // Some of the properties are redundant, but has the core ones...
        this.insertMultipleIndicatorsIntoMap(indicatorGeoList).then(() => {
            this.setState({
                activeModal: null,
                activeModalProps: null
            });
        });
    };

    deleteLayerFromMap = (layerId) => {
        const { activeMapDef } = this.state,
            mask = this.webMapMask.current,
            map = JSON.parse(activeMapDef);
        if (!isNullOrUndefined(mask) && !hasClass(mask, 'in')) addClass(mask, 'in');
        //this.setState({
        //    status: PageActivityStatus.LOADING_MAP
        //}, () =>
        //{
        for (let i = map.itemData.operationalLayers.length - 1; i >= 0; i--) {
            if (map.itemData.operationalLayers[i].id === layerId) {
                map.itemData.operationalLayers.splice(i, 1);
            }
        }
        this.setState(
            {
                status: PageActivityStatus.INACTIVE,
                activeMapDef: JSON.stringify(map)
            },
            () => {
                if (!isNullOrUndefined(mask) && hasClass(mask, 'in')) removeClass(mask, 'in');
            }
        );
    };

    changeLayerInMap = (layerId, layer) => {
        //const { catalog, geo, table, activeMapDef } = this.state,
        //    map = JSON.parse(activeMapDef);
        // Background task - no UI update... (in fact skipping this and making the _map the one "source of truth") - see saveWebMap
        //for (let i = map.itemData.operationalLayers.length - 1; i >= 0; i--)
        //{
        //    if (map.itemData.operationalLayers[i].id === layerId)
        //    {
        //        map.itemData.operationalLayers[i].userSetVisible = layer.visible; // Use a non-standard property, to avoid endless update and render()
        //    }
        //}
        //this.setState({
        //    status: PageActivityStatus.INACTIVE,
        //    activeMapDef: JSON.stringify(map)
        //});
    };

    openWebMap = (itemId, itemInfo) => {
        const { portalUrl, token } = this.props,
            { catalog, table } = this.state,
            mask = this.webMapMask.current;
        if (!isNullOrUndefined(mask) && !hasClass(mask, 'in')) addClass(mask, 'in');
        this.setState(
            {
                status: PageActivityStatus.LOADING_MAP
            },
            () => {
                ArcGISPortal.getItemData(itemId, { f: 'json', token: token }, portalUrl).then((itemData) => {
                    itemInfo.extent = {
                        xmin: itemInfo.extent[0][0],
                        ymin: itemInfo.extent[0][1],
                        xmax: itemInfo.extent[1][0],
                        ymax: itemInfo.extent[1][1],
                        spatialReference: { wkid: 4326 }
                    };
                    // Update the internal state of BOTH, because we will re-use them...
                    this.setState(
                        {
                            status: PageActivityStatus.INACTIVE,
                            initialMapDef: JSON.stringify({
                                item: itemInfo,
                                itemData: itemData
                            }),
                            activeMapDef: JSON.stringify({
                                item: itemInfo,
                                itemData: itemData
                            })
                        },
                        () => {
                            this.props.setPageState('Data Catalog | Web Map Builder', this.getTitleIcon(), null, {
                                id: table.id,
                                text: (
                                    <span>
                                        {itemInfo.title}{' '}
                                        <a
                                            href={`https://www.arcgis.com/home/item.html?id=${itemId}`}
                                            target="iaoArcWindow"
                                        >
                                            <i className="fas fa-external-link-alt" style={{ fontSize: '0.9em' }}></i>
                                        </a>
                                    </span>
                                )
                            });
                            if (!isNullOrUndefined(mask) && hasClass(mask, 'in')) removeClass(mask, 'in');
                            window.history.pushState('', '', `?item=${table.id}&webmap=${itemId}`);
                            checkMapLayersForUpdates(catalog, {
                                item: itemInfo,
                                itemData: itemData
                            }).then((ue) => {
                                if (ue !== false) {
                                    this.setState({
                                        activeModal: 'mapUpdatesDialog',
                                        activeModalProps: ue
                                    });
                                }
                            });
                        }
                    );
                });
            }
        );
    };

    showSaveWebMapDialog = () => {
        const { activeMapDef } = this.state,
            uploadItem = JSON.parse(activeMapDef);
        // If it came from an initial web map load, the item will have an ID... so just echo it...
        if (!isNullOrUndefined(uploadItem.item) && !isNullOrUndefined(uploadItem.item.id)) {
            this.saveWebMap(
                uploadItem.item.title,
                uploadItem.item.tags.join(','),
                uploadItem.item.ownerFolder,
                uploadItem.item.id
            );
        } else {
            this.setState({
                activeModal: 'arcSaveMapDialog',
                activeModalProps: {}
            });
        }
    };

    saveWebMap = async (mapName, mapTags, mapFolder, mapId = null) => {
        const { user, portalUrl, token, intl } = this.props,
            { activeMapDef, table } = this.state,
            mask = this.webMapMask.current,
            uploadItem = JSON.parse(activeMapDef);
        if (isNullOrUndefined(uploadItem.item)) uploadItem.item = {}; // Should never be, but...
        if (
            (isNullOrUndefined(mapId) || isNullOrUndefined(uploadItem.item.extent)) &&
            !isNullOrUndefined(this._arcMapObject)
        ) {
            const v = this._arcMapObject.view,
                x = v.extent.clone(),
                ll = await ArcGISUtils.convertExtentToGeographic(x.toJSON());
            uploadItem.item.extent = ll.toJSON();
        }
        // Reset some of the overrides here, to match the ArcGIS spec (this is done to avoid too many render()s, see changeLayerInMap)
        for (let olyr of uploadItem.itemData.operationalLayers) {
            let mapLyr = this._arcMapObject.view.map.findLayerById(olyr.id);
            if (!isNullOrUndefined(mapLyr)) {
                olyr.visibility = mapLyr.visible === true;
            }
        }
        if (!isNullOrUndefined(mask) && !hasClass(mask, 'in')) addClass(mask, 'in');
        this.setState(
            {
                status: PageActivityStatus.LOADING_MAP
            },
            () => {
                const bail = (error) => {
                    if (!isNullOrUndefined(error.code) && error.code === 400) {
                        this.setState({
                            status: PageActivityStatus.INACTIVE,
                            activeModal: 'arcSaveMapDialog',
                            activeModalProps: {}
                        });
                    } else {
                        this.setState({
                            status: PageActivityStatus.INACTIVE,
                            error: {
                                title: (
                                    <FormattedMessage
                                        id="webmapbuilder.saveWebMap.errorDialog.title"
                                        defaultMessage="Cannot Save Map"
                                        values={{
                                            name: <strong>{mapName}</strong>
                                        }}
                                    />
                                ),
                                message: (
                                    <FormattedMessage
                                        id="webmapbuilder.saveWebMap.errorDialog.messageFormat"
                                        tagName="div"
                                        defaultMessage="Web Map {name} could not be saved to ArcGIS Online. Please try again. The error message was: {message}"
                                        values={{
                                            name: <strong>{mapName}</strong>,
                                            message: (
                                                <div>
                                                    <br />
                                                    <div className="bg-danger pad10">{error.message}</div>
                                                    <br />
                                                    <br />
                                                </div>
                                            )
                                        }}
                                    />
                                ),
                                key: 'WebMapSaveError'
                            }
                        });
                    }
                };
                const uploadParams = {
                        f: 'json',
                        token: token,
                        title: mapName,
                        tags: mapTags,
                        text: JSON.stringify(uploadItem.itemData),
                        type: 'Web Map',
                        extent: !isNullOrUndefined(uploadItem.item.extent.xmin)
                            ? `${uploadItem.item.extent.xmin},${uploadItem.item.extent.ymin},${uploadItem.item.extent.xmax},${uploadItem.item.extent.ymax}`
                            : uploadItem.item.extent[0].join(',') + ',' + uploadItem.item.extent[1].join(',')
                    },
                    arcPromise = !isNullOrUndefined(mapId)
                        ? ArcGISPortal.updateItem(
                              user.username,
                              mapId,
                              mapFolder !== '' ? mapFolder : null,
                              uploadParams,
                              portalUrl
                          )
                        : ArcGISPortal.addItem(
                              user.username,
                              mapFolder !== '' ? mapFolder : null,
                              uploadParams,
                              portalUrl
                          );
                arcPromise
                    .then((itemStatus) => {
                        if (!isNullOrUndefined(itemStatus.error)) {
                            bail(itemStatus.error);
                        } else {
                            const defDesc = intl.formatMessage(
                                    {
                                        id: 'webmapbuilder.mapDescription.messageFormat',
                                        defaultMessage:
                                            '<p>You can add new data to this map in <a href="{url}" target="_blank">InstantAtlas Web Map Builder</a>.</p>'
                                    },
                                    {
                                        url: `${window.location.href.split('?')[0]}?item=${encodeURIComponent(
                                            table.url
                                        )}&webmap=${itemStatus.id}`
                                    }
                                ),
                                updateDesc = isNullOrUndefined(uploadItem.item.description)
                                    ? ArcGISPortal.updateItem(
                                          user.username,
                                          itemStatus.id,
                                          mapFolder !== '' ? mapFolder : null,
                                          {
                                              f: 'json',
                                              token: token,
                                              description: defDesc
                                          },
                                          portalUrl
                                      )
                                    : Promise.resolve({});
                            updateDesc.then(() => {
                                // Update the internal state of BOTH, because we will re-use them...
                                ArcGISPortal.getItemDescription(
                                    itemStatus.id,
                                    { f: 'json', token: token },
                                    portalUrl
                                ).then((itemInfo) => {
                                    this.openWebMap(itemInfo.id, itemInfo);
                                });
                            });
                        }
                    })
                    .catch((saveEx) => {
                        bail(saveEx);
                    });
            }
        );
    };

    changeUserOption = (evt) => {
        const { userOptions, setUserOptions } = this.props,
            box = evt.currentTarget,
            key = box.getAttribute('data-option-key'),
            hasOptions =
                !isNullOrUndefined(userOptions) && userOptions.find((o) => o.id === 'webMapBuilderPage') !== undefined,
            pageOptions = hasOptions ? userOptions.find((o) => o.id === 'webMapBuilderPage') : this.defaultPageOptions;
        if (!isNullOrUndefined(key)) {
            const [key1, key2] = key.split('.');
            if (!isNullOrUndefined(pageOptions[key1])) {
                pageOptions[key1][key2] =
                    !isNullOrUndefined(pageOptions[key1][key2]) && pageOptions[key1][key2] === true ? false : true;
                if (!hasOptions) userOptions.push(pageOptions);
            }
            setUserOptions(userOptions);
        }
        this.optionsButton.current.setState({
            on: false
        });
    };

    render() {
        const { user, portalUrl, portalHome, userOptions, intl } = this.props,
            {
                catalog,
                model,
                catalogs,
                status,
                table,
                mapExtent,
                activeTab,
                activeModal,
                activeModalProps,
                geo,
                initialMapDef,
                activeMapDef,
                error,
                enableScaleButton
            } = this.state,
            auth = user !== null && user.username !== undefined && user.username !== null,
            isLoading = status !== PageActivityStatus.INACTIVE && status !== PageActivityStatus.LOADING_MAP,
            pageOptions =
                !isNullOrUndefined(userOptions) && userOptions.find((o) => o.id === 'webMapBuilderPage') !== undefined
                    ? userOptions.find((o) => o.id === 'webMapBuilderPage')
                    : this.defaultPageOptions;
        if (!auth) {
            const qs = lowerKeyParams(new URLSearchParams(window.location.search));
            return (
                <Redirect
                    to={
                        !isNullOrUndefined(qs['webmap']) && !isNullOrUndefined(qs['item'])
                            ? `/?returnTo=${encodeURIComponent(
                                  `/web-map-builder?item=${encodeURIComponent(qs['item'])}&webmap=${encodeURIComponent(
                                      qs['webmap']
                                  )}`
                              )}`
                            : '/'
                    }
                />
            );
        }
        // Build some controls...
        const catalogValid =
                !isNullOrUndefined(catalog) &&
                !isNullOrUndefined(catalog.master) &&
                !isNullOrUndefined(catalog.master.geos),
            geoName = catalogValid
                ? !isNullOrUndefined(geo) && !isNullOrUndefined(catalog.master.geos.find((g) => g['ID'] === geo))
                    ? catalog.master.geos.find((g) => g['ID'] === geo)['Name']
                    : catalog.master.geos[0]['Name']
                : null,
            iControls = (
                <div className="iao-controls btn-group btn-group-xs">
                    <button
                        className="btn btn-secondary pure-tip pure-tip-bottom"
                        data-action="add-single"
                        data-tooltip={`Add this indicator to the map for ${geoName}`}
                        onClick={this.insertIndicatorIntoMap}
                    >
                        <i className="fas fa-plus"></i>
                    </button>
                    <button
                        className="btn btn-secondary btn-hover-parent pure-tip pure-tip-bottom"
                        data-action="add-all"
                        data-tooltip="Add this indicator for all available layers"
                        onClick={this.insertIndicatorIntoMap}
                    >
                        <i className="fas fa-plus-circle"></i>
                    </button>
                    <button
                        className="btn btn-secondary pure-tip pure-tip-bottom"
                        data-tooltip="View metadata"
                        onClick={this.showMetadataDialog}
                    >
                        {' '}
                        <i className="fas fa-info-circle"></i>
                    </button>
                </div>
            ),
            geos = catalogValid
                ? catalog.master.geos.map((g) => (
                      <option key={g['ID']} value={g['ID']}>
                          {g['Name']}
                      </option>
                  ))
                : null,
            webMapDef = !isNullOrUndefined(activeMapDef) ? JSON.parse(activeMapDef) : { itemData: {} },
            activeMap = this._arcMapObject,
            saveRequired = !isNullOrUndefined(activeMapDef) && initialMapDef !== activeMapDef;

        return (
            <div className="web-map-builder in">
                {isLoading ? null : (
                    <div className="catalog-switcher-top">
                        <ListToggleButton
                            ref={this.toggleButton}
                            text={<i className="fas fa-bars fa-fw"></i>}
                            tooltip="Choose Catalog"
                            className="btn btn-link pure-tip pure-tip-bottom"
                        >
                            <ItemsLinkList
                                items={catalogs}
                                action={this.onCatalogChange}
                                linkIcon={
                                    <span>
                                        <i className="fas fa-fw fa-map-marked-alt"></i>
                                        <i className="fas fa-hammer"></i>{' '}
                                    </span>
                                }
                                extraItems={[
                                    <li key="divider" className="divider"></li>,
                                    <li key="checkbox">
                                        <input
                                            type="checkbox"
                                            onChange={this.onOwnerListChange}
                                            id="userCatalogsBox"
                                            defaultChecked={false}
                                            className="form-control"
                                        />
                                        <label htmlFor="userCatalogsBox" className="control-label slider">
                                            My catalogs only
                                        </label>
                                    </li>
                                ]}
                            />
                        </ListToggleButton>
                        <span className="divider">&nbsp;</span>
                        <button
                            className="btn btn-link pure-tip pure-tip-bottom"
                            type="button"
                            data-tooltip="Open Web Map"
                            onClick={this.showWebMapChooserDialog}
                        >
                            <i className="fas fa-folder-open fa-fw"></i>
                        </button>
                        <button
                            className={`btn btn-link pure-tip pure-tip-bottom ${
                                saveRequired ? 'btn-call-action' : ''
                            }`.trim()}
                            type="button"
                            data-tooltip="Save Web Map"
                            onClick={this.showSaveWebMapDialog}
                            disabled={isNullOrUndefined(webMapDef.itemData.operationalLayers)}
                        >
                            <i className="fas fa-save fa-fw"></i>
                        </button>
                        <button
                            className="btn btn-link pure-tip pure-tip-bottom"
                            type="button"
                            data-tooltip="Reset Web Map"
                            onClick={this.onOwnerListChange}
                        >
                            <i className="fas fa-backspace fa-fw"></i>
                        </button>
                        <ListToggleButton
                            ref={this.optionsButton}
                            text={<i className="fas fa-ellipsis-v fa-fw small"></i>}
                            tooltip="Options"
                            className="btn btn-link pure-tip pure-tip-bottom"
                        >
                            <ul className="dropdown-menu dropdown-menu-right">
                                <li>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => this.changeUserOption(e)}
                                        id="userScalesBox"
                                        data-option-key="map.scales"
                                        defaultChecked={pageOptions.map.scales}
                                        className="form-control"
                                        disabled={!enableScaleButton}
                                    />
                                    <label htmlFor="userScalesBox" className="control-label slider">
                                        Use scale thresholds for map layers
                                    </label>
                                </li>
                                <li>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => this.changeUserOption(e)}
                                        id="oneDatePerLayerBox"
                                        data-option-key="map.oneDatePerLayer"
                                        defaultChecked={pageOptions.map.oneDatePerLayer}
                                        className="form-control"
                                    />
                                    <label htmlFor="oneDatePerLayerBox" className="control-label slider">
                                        One layer per date
                                    </label>
                                </li>
                                <li>
                                    <input
                                        type="checkbox"
                                        onChange={(e) => this.changeUserOption(e)}
                                        id="proportionalCirclesBox"
                                        data-option-key="map.proportionalCircles"
                                        defaultChecked={pageOptions.map.proportionalCircles}
                                        className="form-control"
                                    />
                                    <label htmlFor="proportionalCirclesBox" className="control-label slider">
                                        Proportional circles for <em>count</em> data
                                    </label>
                                </li>
                            </ul>
                        </ListToggleButton>
                        <span className="divider">&nbsp;</span>
                    </div>
                )}
                <div className="row-fluid authContent all-available-height">
                    <div className="col col-md-4 flex-box">
                        <ul className="nav nav-tabs" role="tablist">
                            <li role="presentation" className={activeTab === 'dataExplorer' ? 'active' : ''}>
                                <a
                                    onClick={this.setActiveTab}
                                    href="#dataExplorer"
                                    aria-controls="dataExplorer"
                                    role="tab"
                                    data-toggle="tab"
                                    className="nodef"
                                >
                                    <i className="fas fa-database"></i>
                                    <i className="fas fa-plus" style={{ fontSize: '0.4' }}></i> Data
                                </a>
                            </li>
                            <li role="presentation" className={activeTab === 'mapControls' ? 'active' : ''}>
                                <a
                                    onClick={this.setActiveTab}
                                    href="#mapControls"
                                    aria-controls="mapControls"
                                    role="tab"
                                    data-toggle="tab"
                                    className="nodef"
                                >
                                    <i className="fas fa-layer-group"></i>
                                    <i className="fas fa-cog" style={{ fontSize: '0.4' }}></i> Layers
                                </a>
                            </li>
                            <li
                                role="presentation"
                                className={activeTab === 'mapLegend' ? 'active' : ''}
                                style={{ display: 'none' }}
                            >
                                <a
                                    href="#mapLegend"
                                    aria-controls="mapLegend"
                                    role="tab"
                                    data-toggle="tab"
                                    className="nodef"
                                >
                                    <i className="fas fa-fw fa-paint-brush"></i> Legend
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div
                                role="tabpanel"
                                className={`tab-pane explorer-tree-panel ${
                                    activeTab === 'dataExplorer' ? 'active' : ''
                                }`.trim()}
                                id="dataExplorer"
                            >
                                <div className="form-horizontal iao-data-catalog-container">
                                    <div className="row">
                                        <div className="col-md-12 geo-selector">
                                            <select
                                                className="form-control pure-tip pure-tip-top"
                                                onChange={this.onGeoChange}
                                                defaultValue={geo}
                                            >
                                                {geos}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="explorer-tree-holder">
                                        <div>
                                            {isLoading ? null : (
                                                <ThemeTreePanel
                                                    dragDropEnabled={false}
                                                    model={model}
                                                    table={table}
                                                    rootLabel="Choose data for your map..."
                                                    controls={{
                                                        theme: {},
                                                        indicator: iControls
                                                    }}
                                                    intl={intl}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                role="tabpanel"
                                className={`tab-pane map-controls-panel ${
                                    activeTab === 'mapControls' ? 'active' : ''
                                }`.trim()}
                                id="mapControls"
                            >
                                <div id="mapControlsSet">
                                    {!isNullOrUndefined(activeMap) &&
                                    !isNullOrUndefined(activeMap.view.map.layers) &&
                                    activeMap.view.map.layers.items.length > 0 ? (
                                        <ArcWebMapLayerList
                                            ref={this.webMapLegend}
                                            view={activeMap.view}
                                            layers={webMapDef.itemData.operationalLayers}
                                            onDeleteLayer={this.deleteLayerFromMap}
                                            onLayerChange={this.changeLayerInMap}
                                        />
                                    ) : (
                                        <p>
                                            <i className="fas fa-info-circle fa-3x fa-pull-left fa-border"></i> Use the{' '}
                                            <strong>Data</strong> tab to choose data from the InstantAtlas&trade; Data
                                            Catalog and add it to the map.
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div
                                role="tabpanel"
                                className={`tab-pane map-legend-panel ${
                                    activeTab === 'mapLegend' ? 'active' : ''
                                }`.trim()}
                                id="mapLegend"
                            ></div>
                        </div>
                    </div>
                    <div className="col col-md-8 map-panel">
                        <div id="arcMapPanel" style={{ width: '100%', height: '100%' }}>
                            <ArcWebMap
                                ref={this.webMap}
                                map={initialMapDef}
                                mapExtent={mapExtent}
                                onMapLoad={this.collectMap}
                                onMapError={this.handleMapError}
                            />
                            <div
                                ref={this.webMapMask}
                                className={`map-masker ${status === PageActivityStatus.LOADING_MAP ? 'in' : ''}`}
                            >
                                <ProgressMessage message="" />
                            </div>
                        </div>
                        {isLoading ? (
                            <div id="arcMapHelpPanel" className="map-cover-panel in">
                                <div className="cover-page">
                                    <div style={{ marginTop: '3em', marginBottom: '3em' }}>
                                        <ProgressMessage />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
                <MetadataDialog
                    show={activeModal === 'metadataDialog'}
                    editable={false}
                    item={{ ...activeModalProps }}
                    catalog={catalog}
                    onClose={this.hideModal}
                />
                <ChooseArcItemDialog
                    show={activeModal === 'arcChooseMapDialog'}
                    title={
                        <FormattedMessage
                            id="webmapBuilder.chooseWebMapDialog.title"
                            defaultMessage="{icon} Choose Web Map..."
                            values={{
                                icon: <i className="fas fa-map-marked-alt"></i>
                            }}
                        />
                    }
                    onChoose={this.openWebMap}
                    onClose={this.hideModal}
                    itemType="Web Map"
                    catalog={catalog}
                    user={user}
                    portalUrl={portalUrl}
                    portalHome={portalHome}
                    customArgs={{ ...activeModalProps }}
                />
                <ArcItemSaveDialog
                    show={activeModal === 'arcSaveMapDialog'}
                    title={
                        <FormattedMessage
                            id="webmapBuilder.saveWebMapDialog.title"
                            defaultMessage="{icon} Save Web Map As..."
                            values={{
                                icon: <i className="fas fa-map-marked-alt"></i>
                            }}
                        />
                    }
                    onChoose={this.saveWebMap}
                    onClose={this.hideModal}
                    text="New Web Map"
                    tags="Map,InstantAtlas,ia-item-type=CatalogIndicatorMap"
                    catalog={catalog}
                    user={user}
                    portalUrl={portalUrl}
                    customArgs={{ ...activeModalProps }}
                />
                {error !== null && error.message !== undefined ? (
                    <ModalDialog
                        title={error.title !== undefined ? error.title : 'Error'}
                        show={true}
                        onClose={this.clearError}
                        buttons={
                            !isNullOrUndefined(error.buttons)
                                ? error.buttons
                                : [
                                      <button type="button" className="btn btn-secondary" onClick={this.clearError}>
                                          <FormattedMessage
                                              id="webmapbuilder.errorDialog.button.close"
                                              defaultMessage="{icon} Close"
                                              values={{
                                                  icon: <i className="fas fa-times"></i>
                                              }}
                                          />
                                      </button>
                                  ]
                        }
                    >
                        <div>
                            <div>
                                <div
                                    style={{
                                        float: 'left',
                                        marginRight: 10 + 'px',
                                        marginBottom: 10 + 'px',
                                        fontSize: '48px'
                                    }}
                                >
                                    {error.type !== undefined && error.type === 'warning' ? (
                                        <i className="fas fa-exclamation-triangle"></i>
                                    ) : (
                                        <i className="fas fa-times-circle"></i>
                                    )}
                                </div>
                                <div style={{ marginLeft: 68 + 'px' }}>{error.message}</div>
                            </div>
                        </div>
                    </ModalDialog>
                ) : null}
                {activeModal === 'mapUpdatesDialog' && !isNullOrUndefined(activeModalProps) ? (
                    <ModalDialog
                        title="Update Map Layers with Latest Data?"
                        show={true}
                        large={true}
                        scroll={true}
                        onClose={this.hideModal}
                        buttons={[
                            <button type="button" className="btn btn-primary" onClick={this.commitToIndicatorMapUpdate}>
                                <FormattedMessage
                                    id="manager.errorDialog.button.update"
                                    defaultMessage="{icon} Update"
                                    values={{
                                        icon: <i className="fas fa-redo"></i>
                                    }}
                                />
                            </button>,
                            <button type="button" className="btn btn-secondary" onClick={this.clearError}>
                                <FormattedMessage
                                    id="manager.errorDialog.button.close"
                                    defaultMessage="{icon} Close"
                                    values={{
                                        icon: <i className="fas fa-times"></i>
                                    }}
                                />
                            </button>
                        ]}
                    >
                        <div>
                            <div>
                                <div
                                    style={{
                                        float: 'left',
                                        marginRight: 10 + 'px',
                                        marginBottom: 10 + 'px',
                                        fontSize: '48px'
                                    }}
                                >
                                    <i className="far fa-calendar-alt"></i>
                                </div>
                                <div style={{ marginLeft: 68 + 'px' }}>
                                    <p>
                                        There are new data available in for one or more of the layers in your map. Do
                                        you want to update these layers to show the latest data?
                                    </p>
                                    <div>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Layer</th>
                                                    <th scope="col">Current Date</th>
                                                    <th scope="col">Latest Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {activeModalProps.map((uitem, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                id={`updateLayer_${uitem.id}`}
                                                                className="form-control"
                                                                value={uitem.id}
                                                                defaultChecked={uitem.checked}
                                                                onChange={(e) =>
                                                                    this.onUpdateLayerToggleChange(uitem.id)
                                                                }
                                                            />
                                                            <label
                                                                htmlFor={`updateLayer_${uitem.id}`}
                                                                className="control-label"
                                                            >
                                                                {uitem.name}
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <span
                                                                className="date-field-label"
                                                                data-field-name={uitem.current.field}
                                                            >
                                                                {uitem.current.label}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span
                                                                className="date-field-label"
                                                                data-field-name={uitem.latest.field}
                                                            >
                                                                {uitem.latest.label}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalDialog>
                ) : null}
            </div>
        );
    }
}

const checkMapLayersForUpdates = (catalog, webMap) => {
    const layerLookup = {},
        latestLookup = {};
    let fq,
        p,
        fields = [],
        idf = 'ID',
        nmf = 'Name',
        iidf = 'Indicator_ID',
        iof = 'Item_Order',
        fidf = 'FIELD_ID',
        urlf = 'SERVICE_URL',
        didf = 'DATE_ID',
        geof = 'GEO_ID',
        url,
        fld,
        iid,
        webMapDef = webMap.itemData;
    if (webMapDef.operationalLayers && webMapDef.operationalLayers.length > 0) {
        for (let olyr of webMapDef.operationalLayers) {
            if (
                olyr.layerDefinition &&
                olyr.layerDefinition.drawingInfo &&
                olyr.layerDefinition.drawingInfo.renderer &&
                olyr.layerDefinition.drawingInfo.renderer.field
            ) {
                fq = `(FIELD_ID='${olyr.layerDefinition.drawingInfo.renderer.field}' AND SERVICE_URL='${olyr.url}')`;
                if (fields.indexOf(fq) < 0) fields.push(fq);
                layerLookup[olyr.id] = JSON.parse(JSON.stringify(olyr));
                latestLookup[olyr.url] = {};
            }
        }
        if (fields.length > 0) {
            p = {
                f: 'json',
                outFields: 'ID,Name,Indicator_ID,Item_Order,Field_ID,Service_URL,Date_ID,Geo_ID',
                orderByFields: 'Indicator_ID,Date_ID,Item_Order'
            };
            // Get the initial list of Instances
            return catalog
                .queryMasterTable(`Item_Type='Instance' AND (${fields.join(' OR ')})`, p, true)
                .then((results) => {
                    if (results && results.fields && results.features && results.features.length > 0) {
                        idf = results.fields.find((f) => f.name.toLowerCase() === idf.toLowerCase()).name;
                        nmf = results.fields.find((f) => f.name.toLowerCase() === nmf.toLowerCase()).name;
                        iidf = results.fields.find((f) => f.name.toLowerCase() === iidf.toLowerCase()).name;
                        iof = results.fields.find((f) => f.name.toLowerCase() === iof.toLowerCase()).name;
                        fidf = results.fields.find((f) => f.name.toLowerCase() === fidf.toLowerCase()).name;
                        urlf = results.fields.find((f) => f.name.toLowerCase() === urlf.toLowerCase()).name;
                        didf = results.fields.find((f) => f.name.toLowerCase() === didf.toLowerCase()).name;
                        geof = results.fields.find((f) => f.name.toLowerCase() === geof.toLowerCase()).name;
                        fields = [];
                        for (let f of results.features) {
                            if (f.attributes[didf] && f.attributes[urlf]) {
                                url = f.attributes[urlf];
                                fld = f.attributes[fidf];
                                iid = f.attributes[iidf];
                                fq = `(Indicator_ID='${f.attributes[iidf]}' AND GEO_ID='${
                                    f.attributes[geof]
                                }' AND (DATE_ID > '${new Date(f.attributes[didf]).toISOString()}' OR Item_Order > ${
                                    f.attributes[iof]
                                }) AND SERVICE_URL='${url}')`;
                                if (fields.indexOf(fq) < 0) fields.push(fq);
                                latestLookup[url][iid] = {
                                    label: f.attributes[nmf],
                                    field: fld,
                                    date: f.attributes[didf],
                                    geo: f.attributes[geof]
                                };
                            }
                        }
                        if (fields.length > 0) {
                            // Now get the initial list of Instances whose date is more recent...
                            return catalog
                                .queryMasterTable(`Item_Type='Instance' AND (${fields.join(' OR ')})`, p, true)
                                .then((results) => {
                                    if (results && results.fields && results.features && results.features.length > 0) {
                                        for (let f of results.features) {
                                            if (f.attributes[iidf] && f.attributes[urlf]) {
                                                url = f.attributes[urlf];
                                                fld = f.attributes[fidf];
                                                iid = f.attributes[iidf];
                                                latestLookup[url][iid].replacement = {
                                                    label: f.attributes[nmf],
                                                    field: fld,
                                                    date: f.attributes[didf]
                                                };
                                            }
                                        }
                                        fields = [];
                                        for (let i in layerLookup) {
                                            url = layerLookup[i].url;
                                            if (latestLookup[url] !== undefined) {
                                                fld = layerLookup[i].layerDefinition.drawingInfo.renderer.field;
                                                for (let iid in latestLookup[url]) {
                                                    if (
                                                        latestLookup[url][iid].field === fld &&
                                                        latestLookup[url][iid].replacement !== undefined
                                                    ) {
                                                        fields.push({
                                                            id: layerLookup[i].id,
                                                            name: layerLookup[i].title || layerLookup[i].name,
                                                            current: {
                                                                label: latestLookup[url][iid].label,
                                                                field: latestLookup[url][iid].field
                                                            },
                                                            latest: {
                                                                label: latestLookup[url][iid].replacement.label,
                                                                field: latestLookup[url][iid].replacement.field
                                                            },
                                                            url: url,
                                                            indicator: iid,
                                                            geo: latestLookup[url][iid].geo,
                                                            checked: true
                                                        });
                                                    }
                                                }
                                                //if (fields.length > 0)
                                                //{
                                                //    iao.showPopupScriptDialog(iao.getText('UpdateCatalogMapLayersToLatestDialog.Title', 'Update Map Layers with Latest Data?'),
                                                //        iao.getText('UpdateCatalogMapLayersToLatestDialog.MessageFormat', '<p>There are new data available in for one or more of the layers in ' +
                                                //            'your map <strong>{0}<\/strong>. Do you want to update these layers to show the latest data?<\/p>')
                                                //            .replace(/\{0\}/g, agoWebMap.item.title) +
                                                //        '<div><table class="table table-striped"><thead><tr><th scope="col">Layer<\/th><th scope="col">Current Date<\/th><th scope="col">Latest Date<\/th><\/tr><\/thead>' +
                                                //        '<tbody>' + fields.join('') + '<\/tbody><\/table><\/div>', {
                                                //            modal: true,
                                                //            large: true,
                                                //            scrolling: true,
                                                //            type: 'calendar-alt',
                                                //            buttons: 'okcancel',
                                                //            buttonText: {
                                                //                ok: iao.getText('UpdateCatalogMapLayersToLatestDialog.YesButton.Text', 'Yes'),
                                                //                cancel: iao.getText('UpdateCatalogMapLayersToLatestDialog.NoButton.Text', 'No')
                                                //            },
                                                //            ok: function (e)
                                                //            {
                                                //                var $cb = $(e.target).parents('.modal-content').find('.form-control:checked');
                                                //                $cb.each(function (idx)
                                                //                {
                                                //                    var v = $(this).val().split(';'),
                                                //                        iname = $('.data-catalog-source li.ind[data-ind-uuid="' + iid + '"] > .i-name').text();
                                                //                    iao.arcgis.insertIndicatorIntoMap(v[1], catalog.url + (catalog.url.match(/.*\/FeatureServer\/[0-9]+$/) ? '' : '\/0'), iname, {
                                                //                        id: v[0],
                                                //                        replace: true,
                                                //                        title: $(this).siblings('label').text().replace(v[5], v[6]),
                                                //                        webmap: agoWebMap,
                                                //                        map: agoMap,
                                                //                        legend: agoLegend,
                                                //                        geos: v.slice(2, 3),
                                                //                        excludes: ['G8', 'G9'],
                                                //                        popup: false,
                                                //                        scales: false, // No scale thresholding by default
                                                //                        chart: 'line',
                                                //                        mask: showWorkingMessage,
                                                //                        container: 'iaoMapPanel',
                                                //                        menu: '.explorer-tree-panel select:eq(0)',
                                                //                        token: p.token || iao.getToken(),
                                                //                        done: function (webMapAdjusted, lyrList)
                                                //                        {
                                                //                            //agoWebMap = webMapAdjusted;
                                                //                            //agoWebMap.itemData.operationalLayers = lyrList;
                                                //                            mapListHandler(lyrList);
                                                //                            mapZoomHandler({});
                                                //                            $('.page-save').addClass('pageSaveNeeded');
                                                //                        }
                                                //                    });
                                                //                });
                                                //            },
                                                //            focus: 'ok'
                                                //        });
                                                //}
                                            }
                                        }
                                        return fields;
                                    }
                                    // Bounced all the way through, return an empty promise
                                    return Promise.resolve(false);
                                });
                        }
                    }
                    // Bounced all the way through, return an empty promise
                    return Promise.resolve(false);
                });
        }
    }
    // Bounced all the way through, return an empty promise
    return Promise.resolve(false);
};

const mapStateToProps = (state) => {
    return {
        token: state.hubAppSettings.token,
        portalUrl: state.hubAppSettings.portalUrl,
        portalType: state.hubAppSettings.portalType,
        portalHome: state.hubAppSettings.portalHome,
        appAuthId: state.hubAppSettings.appAuthId,
        user: state.hubAppSettings.user,
        userOptions: state.userOptions,
        tokenManager: state.applicationState.tokenManager
    };
};

const actionCreators = {
    setPageState,
    setUserOptions
};

export default connect(mapStateToProps, actionCreators)(withRouter(injectIntl(WebMapBuilderPage)));
